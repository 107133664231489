<template>
  <v-col cols="12">
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Title"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              @keypress="isNumber($event)"
              label="Percentage"
              color="primary"
              maxlength="5"
              :counter="5"
              @keyup="update"
              v-model="detail.percentage">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              @keypress="isNumber($event)"
              label="No. Of Installments"
              color="primary"
              maxlength="3"
              :counter="3"
              @keyup="update"
              v-model="detail.instalments">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
              clearable
              outlined
              dense
              :items="frequencies"
              v-model="detail.frequency"
              item-text="title"
              item-value="title"
              label="Frequency"
              color="primary"
              @keyup="update">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";

export default {
  name: 'project-payment-plan-distribution-pp',
  props: ['moduleOf', 'projectPaymentPlanDistributionOld', 'frequencies'],
  data() {
    return {
      details: [
        {title: '', percentage: '', instalments: '', frequency: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.projectpaymentplans.projectPaymentPlanDistributionPP = v;
      },
      deep: true
    },
    projectPaymentPlanDistributionOld: {
      handler() {
        if (this.projectPaymentPlanDistributionOld.length > 0) {
          this.details = this.projectPaymentPlanDistributionOld
        } else {
          this.details = [
            {title: '', percentage: '', instalments: '', frequency: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.projectPaymentPlanDistributionOld.length > 0) {
      this.details = this.projectPaymentPlanDistributionOld
    } else {
      this.details = [
        {title: '', percentage: '', instalments: '', frequency: ''}
      ]
    }
    bus.$on("projectPaymentPlanDistributionByModal", () => {
      this.details = [
        {title: '', percentage: '', instalments: '', frequency: ''}
      ]
      this.$store.state.projectpaymentplans.projectPaymentPlanDistributionPP = [];
    });
  },
  methods: {
    add() {
      this.details.push({title: '', percentage: '', instalments: '', frequency: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
  }
}
</script>