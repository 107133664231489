<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeDetailDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        <span v-html="item.title"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="activeTabDetail" background-color="#002366" left dark>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Basic Details</v-tab>
      <v-tab>Media</v-tab>

      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <h6>Title:</h6>
              <p v-html="item.title"></p>
            </v-col>
            <v-col cols="12">
              <h6>Description:</h6>
              <p v-html="item.description ? item.description : '-'"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-tabs
            v-model="activeTabDetailInner"
            background-color="primary" left dark>
          <v-tab>Gallery</v-tab>
          <v-tab>Brochure</v-tab>

          <v-tab-item>
            <Gallery v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0" :items="item.gallery_files"/>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('brochure_files') && item.brochure_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.brochure_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Gallery from "@/view/pages/realstate/media/Gallery";
export default {
  name: 'project-external-layout-details',
  components: {Gallery},
  props: ['item'],
  data() {
    return {}
  },
  watch: {
    item() {
      this.activeTabDetail = 0
      this.activeTabDetailInner = 0
    }
  },
  mounted() {},
  methods: {},
  computed: {}
};
</script>