<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h4>{{ moduleTitle }}</h4>
                  <v-row>
                    <v-col
                        cols="12" sm="4"
                        class="uploaded-files"
                        v-for="file in uploadedFilesOld"
                        :key="file.id"
                    >
                      <div v-if="moduleType === 'main'">
                        <a v-if="file && file.hasOwnProperty('file_public_url')"
                           :href="file.file_public_url" target="_blank">{{ file.file_public_url }}</a>
                        <v-img v-if="file && file.hasOwnProperty('image_public_url')"
                               :src="file.image_public_url"></v-img>
                      </div>
                      <v-btn
                          dark
                          x-small
                          color="red"
                          @click="removeUploadedFileOld(file)"
                      >
                        <v-icon dark>
                          fas fa-times
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-btn
                      color="primary"
                      dark
                      class="ml-auto ma-3"
                      style="width:100%"
                      @click="uploadFiles"
                      v-if="isFilesLoaded"
                  >
                    Upload Files
                    <v-icon class="ml-2" small>fas fa-upload</v-icon>
                  </v-btn>
                  <v-select
                      background-color="#00004d"
                      dark
                      dense
                      solo
                      v-model="galleryModulePurpose"
                      v-if="moduleTitle === 'Gallery' && isFilesLoaded"
                      clearable
                      label="Select File Type"
                      :items="galleyPurposeFilters"
                      item-text="title"
                      item-value="value"
                  ></v-select>
                  <file-pond
                      name="files"
                      ref="pond"
                      maxFileSize="5MB"
                      v-if="multipleCheck"
                      label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
                      :allow-multiple="moduleMultiple"
                      :accepted-file-types="acceptedFiles"
                      v-model="files"
                      max-files="100"
                      @init="handleFilePondInit"
                      @addfile="handleFileAdded"
                      @removefile="handleFileRemoved"
                  />
                  <v-btn
                      color="red"
                      dark
                      class="ml-auto ma-3"
                      style="width:100%"
                      @click="removeUploadedFiles"
                      v-if="uploadedFiles.length > 0"
                  >
                    Remove All
                    <v-icon class="ml-2" small>fas fa-trash</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                    cols="12" sm="4"
                    class="uploaded-files"
                    v-for="file in uploadedFiles"
                    :key="file.id"
                >
                  <v-form>
                    <v-text-field outlined
                                  dense
                                  v-if="moduleType === 'other'"
                                  disabled v-model="file.original_file_name"
                                  label="Original File Name"
                    ></v-text-field>
                    <v-text-field outlined
                                  dense
                                  v-if="moduleType === 'other'" :counter="200" v-model="file.file_name"
                                  label="Edit File Name"
                    ></v-text-field>
                    <v-text-field outlined
                                  dense
                                  v-if="moduleType === 'other'"
                                  :counter="10"
                                  maxLength="10"
                                  @keypress="isNumber($event)"
                                  v-model="file.sort_order"
                                  label="Sort Order"
                    ></v-text-field>
                    <v-autocomplete
                        v-model="file.folder_id"
                        :items="allFolders"
                        item-text="name"
                        item-value="id"
                        dense
                        outlined
                        deletable-chips
                        chips
                        small-chips
                        label="Folder"
                    >
                    </v-autocomplete>
                    <v-text-field outlined
                                  dense
                                  v-if="moduleType === 'other'" :counter="100" v-model="file.tags"
                                  label="Tags"
                    ></v-text-field>
                    <v-textarea outlined
                                dense
                                v-if="moduleType === 'other'" :counter="300" v-model="file.description"
                                label="Description"
                    ></v-textarea>
                  </v-form>
                  <a v-if="moduleType === 'other'" :href="file.url" target="_blank">{{ file.url }}</a>
                  <v-img v-if="moduleType === 'main'" :src="file.url"></v-img>
                  <v-btn
                      dark
                      x-small
                      color="red"
                      @click="removeUploadedFile(file)"
                  >
                    <v-icon dark>
                      fas fa-times
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// import {bus} from "@/main";
// import ModuleFiles from "@/view/pages/realstate/media/inc/ModuleFiles";
// import ModuleVideos from "@/view/pages/realstate/media/inc/ModuleVideos";
import {bus} from "@/main";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {GET_ALL_FOLDERS} from "@/core/services/store/uploads.module";
// import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
);
export default {
  name: "module-media-second",
  props: [
    "level",
    "acceptedFiles",
    "moduleTitle",
    "moduleType",
    "moduleOf",
    "moduleFor",
    "moduleMultiple",
    "deleteModule",
    "moduleFiles",
    "moduleOfFolder",
    "moduleFilesOld"
  ],
  data() {
    return {
      files: [],
      uploadedFiles: [],
      uploadedReadyFiles: [],
      uploadedFilesOld: [],
      allFolders: [],
      isFilesLoaded: false,
      isNamesReady: false,
      multipleCheck: true,
      galleryModulePurpose: ''
    };
  },
  watch: {
    uploadedFiles: {
      handler(v) {
        let that = this;
        if (that.moduleType === "main") {
          v.forEach(vv => {
            that.$store.state.uploads.uploadedStateFiles.push(vv);
          });
        } else {
          Object.assign(that.$store.state.uploads.uploadedStateOtherFilesSecond, {[that.moduleFor]: v});
        }
      },
      deep: true
    },
    moduleFilesOld: {
      handler() {
        this.uploadedFilesOld = this.moduleFilesOld;
        this.multipleCheck = this.moduleMultiple ? true : (this.uploadedFilesOld.length === 0 && this.uploadedFiles.length === 0);
      },
      deep: true
    }
  },
  mounted() {
    this.uploadedFilesOld = this.moduleFilesOld;
    this.multipleCheck = this.moduleMultiple ? true : (this.uploadedFilesOld.length === 0 && this.uploadedFiles.length === 0);
    bus.$on("moduleFilesSecondDeletedByModal", () => {
      this.files = [];
      if (this.uploadedFiles.length > 0){
        this.removeUploadedFiles();
      } else {
        this.uploadedFiles = [];
      }
      this.isFilesLoaded = false;
      this.isNamesReady = false;
      this.multipleCheck = true;
      this.$refs.pond.removeFiles();
      this.$store.state.uploads.uploadedStateOtherFilesSecond = {};
    });
    // bus.$on('moduleModalClosed', () => {
    //     this.$refs.pond.removeFiles();
    // });
    // bus.$on('editModalTriggered', () => {
    //   console.log(123);
    //   // this.uploadedFilesOld = moduleImages;
    // });
  },
  methods: {
    handleFileAdded(e) {
      !e ? (this.isFilesLoaded = true) : (this.isFilesLoaded = false);
      // const formData = new FormData();
      // formData.append('file', f.file, f.file.name);
      // formData.append('type', 'image');
      // this.uploadImage(formData);
    },
    handleFileRemoved() {
      if (this.files.length === 0) {
        this.isFilesLoaded = false;
      }
      // const formData = new FormData();
      // formData.append('file', f.file, f.file.name);
      // formData.append('type', 'image');
      // this.uploadImage(formData);
    },
    uploadFiles() {
      const formData = new FormData();
      this.files.forEach(v => {
        formData.append("files[]", v.file, v.file.name);
      });
      formData.append("model", this.moduleOf);
      formData.append("moduleTitle", this.moduleTitle);
      formData.append("galleryModulePurpose", this.galleryModulePurpose);
      // this.$store.dispatch(CLEAR_LISTING_ERRORS);
      this.$store.dispatch("upload_file", formData).then(data => {
        data.records.forEach(v => {
          this.uploadedFiles.push(v);
        });
        this.$refs.pond.removeFiles();
        this.multipleCheck = this.moduleMultiple;
        this.isFilesLoaded = false;
        this.isNamesReady = true;
        this.getAllFolders()
      });
    },
    removeUploadedFile(file) {
      let that = this;
      const index = this.uploadedFiles.findIndex(element => {
        if (element.id === file.id) {
          return true;
        }
      });
      if (index > -1) {
        this.$store
            .dispatch("delete_uploaded_file", {
              file: file,
              model: this.moduleOf,
              type: "temp"
            })
            .then(() => {
              that.uploadedFiles.splice(index, 1);
              if (that.moduleType === 'main') {
                that.$store.state.uploads.uploadedStateFiles.splice(index, 1);
              }
              if (that.uploadedFiles.length === 0) that.isNamesReady = false;
              that.multipleCheck = true;
            });
      }
    },
    removeUploadedFiles() {
      let that = this;
      if (this.uploadedFiles.length > 0) {
        this.$store
            .dispatch("delete_uploaded_files", {
              files: this.uploadedFiles,
              model: this.moduleOf,
              type: "temp"
            })
            .then(() => {
              that.uploadedFiles = [];
              if (that.moduleType === 'main') {
                that.$store.state.uploads.uploadedStateFiles = [];
              }
              if (that.uploadedFiles.length === 0) that.isNamesReady = false;
              that.multipleCheck = true;
            });
      }
    },
    removeUploadedFileOld(file) {
      let that = this;
      let path = file.hasOwnProperty('image_public_url') ? file.image : file.url;
      const index = this.uploadedFilesOld.findIndex(element => {
        if (element.id === file.id) {
          return true;
        }
      });
      if (index > -1) {
        this.$store
            .dispatch("delete_uploaded_file", {
              file: path,
              model: this.moduleOf,
              delete_model: this.deleteModule,
              type: "old"
            })
            .then(() => {
              that.uploadedFilesOld.splice(index, 1);
              this.multipleCheck = true;
            });
      }
    },
    getOldFilePublicUrl(file) {
      if (
          file &&
          file.hasOwnProperty("image_public_url")
      ) {
        return file.image_public_url;
      } else {
        return file.file_public_url;
      }
    },
    saveFileNames() {
      let that = this;
      let arr = [];
      if (this.fileNameReq) {
        this.uploadedReadyFiles.findIndex(element => {
          that.$set(element, 'name_req', that.fileNameReq);
          if (element.file_name) {
            arr.push(element);
          }
        });
        if (arr.length === this.uploadedReadyFiles.length) {
          this.$store.dispatch(SET_SNACK, {
            clr: "success",
            msg: "Names saved successfully"
          });
        } else {
          that.$store.dispatch(SET_SNACK, {
            clr: "error",
            msg: "Name is required for all files"
          });
        }
        this.$store.state.uploads.uploadedStateOtherFilesSecond = this.$store.state.uploads.uploadedStateOtherFilesSecond.concat(this.uploadedReadyFiles);
        setTimeout(function () {
          that.uploadedReadyFiles = []
        }, 100)
      } else {
        this.uploadedReadyFiles.findIndex(element => {
          that.$set(element, 'name_req', that.fileNameReq);
        });
        this.$store.state.uploads.uploadedStateOtherFilesSecond = this.$store.state.uploads.uploadedStateOtherFilesSecond.concat(this.uploadedReadyFiles);
        this.$store.dispatch(SET_SNACK, {
          clr: "success",
          msg: "Names saved successfully"
        });
        setTimeout(function () {
          that.uploadedReadyFiles = []
        }, 100)
      }
    },
    handleFilePondInit() {
      // console.log('FilePond has initialized');
      // FilePond instance methods are available on `this.$refs.pond`
    },
    getAllFolders() {
      this.$store.dispatch(GET_ALL_FOLDERS, {
        moduleTitle: this.moduleTitle,
        moduleOfFolder: this.moduleOfFolder,
      }).then((data) => {
        this.allFolders = data.records.all_folders;
      });
    }
  },
  components: {
    FilePond
  },
  computed: {}
};
</script>
