<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" fullscreen>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn color="#00004d" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon small>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
                      <span v-else>Create Project Typical Floor Plan</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-tabs v-model="activeTabSecond" background-color="#002366" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Basic Details</v-tab>
                      <v-tab>Media</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                                ref="form"
                                v-model="validationRules.valid"
                                lazy-validation
                            >
                              <v-row>
                                <v-col cols="12" sm="6">
                                  <v-text-field clearable
                                                outlined
                                                dense v-model="editedItem.title" :counter="50"
                                                :rules="validationRules.titleRules"
                                                label="Title*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.floor_level"
                                                :counter="50"
                                                label="Floor Level"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.floor_from"
                                                :counter="50"
                                                label="From Level"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.floor_to"
                                                :counter="50"
                                                label="To Level"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <h5>Description</h5>
                                  <br>
<!--                                  <quill-editor-->
<!--                                      style="height: 300px"-->
<!--                                      v-model="editedItem.description"-->
<!--                                  />-->
                                  <editor
                                      :api-key="tinyKey"
                                      ref="rc_editor"
                                      v-model="editedItem.description"
                                      :init="tinyEditorInit"
                                  />
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-tabs
                            background-color="primary" left dark>
                          <v-tabs-slider></v-tabs-slider>
                          <v-tab>Gallery</v-tab>
                          <v-tab>Typical Floor Plans</v-tab>
                          <v-tab-item>
                            <ModuleMediaSecond
                                moduleType="other"
                                moduleOf="project_typical_floor_plan_files"
                                moduleTitle="Gallery"
                                moduleFor="project_typical_floor_plan_gallery"
                                acceptedFiles="image/jpeg, image/png"
                                :moduleMultiple="true"
                                :deleteModule="true"
                                :moduleFilesOld="[]"
                                moduleOfFolder="project_typical_floor_plans"
                            ></ModuleMediaSecond>
                            <ModuleUploadedFiles
                                v-if="showUploadedFiles"
                                :moduleItem="editedItem"
                                moduleOf="project_typical_floor_plan_files"
                                moduleKey="p_typical_floor_plan_id"
                                moduleTitle="Gallery"
                                moduleOfFolder="project_typical_floor_plans"
                            ></ModuleUploadedFiles>
                          </v-tab-item>
                          <v-tab-item>
                            <ModuleMediaSecond
                                moduleType="other"
                                moduleOf="project_typical_floor_plan_files"
                                moduleTitle="Typical Floor Plans"
                                moduleFor="project_typical_floor_plan_brochure"
                                acceptedFiles="application/pdf"
                                :moduleMultiple="true"
                                :deleteModule="true"
                                :moduleFilesOld="[]"
                                moduleOfFolder="project_typical_floor_plans"
                            ></ModuleMediaSecond>
                            <ModuleUploadedFiles
                                v-if="showUploadedFiles"
                                :moduleItem="editedItem"
                                moduleOf="project_typical_floor_plan_files"
                                moduleKey="p_typical_floor_plan_id"
                                moduleTitle="Typical Floor Plans"
                                moduleOfFolder="project_typical_floor_plans"
                            ></ModuleUploadedFiles>
                          </v-tab-item>
                        </v-tabs>
                      </v-tab-item>
                    </v-tabs>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="detailDialog" fullscreen>
                <v-card>
                  <ProjectTypicalFloorPlanDetails :item="detailItem"
                                                @closeDetailDialog="detailDialog = false" />
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-card-title>
                {{ moduleTitle }} ({{ addTSBVZ(totalRecords) }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  ref="mrc-ptf-datatable"
                  class="elevation-0">
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }} by
                    {{ item.created_user_name }}
                  </div>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.updated_at) }} by
                    {{ item.updated_user_name }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn
                        color="warning"
                        dark class="mr-2"
                        @click="showEditDialog('detail',item)"
                    >
                      View Details
                      <v-icon dark right small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn color="#00004d" @click="showEditDialog('edit',item)" dark class="mr-2">
                      Edit
                      <v-icon dark right small>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn color="red" dark @click="deleteItem(item)" class="mr-2">
                      Delete
                      <v-icon dark right small>fas fa-trash</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/main";
import {
  PROJECTTYPICALFLOORPLANS_LIST,
  CREATE_PROJECTTYPICALFLOORPLANS,
  UPDATE_PROJECTTYPICALFLOORPLANS,
  CLEAR_PROJECTTYPICALFLOORPLANS_ERRORS,
  DELETE_PROJECTTYPICALFLOORPLANS, GET_PROJECTTYPICALFLOORPLANS_DETAILS,
} from "@/core/services/store/projecttypicalfloorplans.module";
import ModuleMediaSecond from "@/view/pages/realstate/media/ModuleMediaSecond";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {BRANDS_SIMPLE_LIST} from "@/core/services/store/brands.module";
import ProjectTypicalFloorPlanDetails from "@/view/pages/realstate/projects/inc/ProjectTypicalFloorPlanDetails";

export default {
  name: 'project-typical-floor-plans',
  components: {
    ProjectTypicalFloorPlanDetails,
    ModuleMediaSecond,
    ModuleUploadedFiles,
  },
  props: ['project', 'moduleTitle'],
  data() {
    return {
      headers: [
        {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: true},
        {text: 'Floor Level', value: 'floor_level', name: 'floor_level', filterable: true, sortable: true},
        {text: 'From', value: 'floor_from', name: 'floor_from', filterable: true, sortable: true},
        {text: 'To', value: 'floor_to', name: 'floor_to', filterable: true, sortable: true},
        {text: "Created", value: "created_at", name: "created_at", filterable: true, sortable: true},
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: true, sortable: true},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than 1 characters",
          v => (v && v.length <= 50) || "Must be less than 50 characters"
        ],
      },
      propertyTypesFilter: [],
      brandsFilter: [],
      items: [],
      dialog: false,
      editedItem: {},
      detailDialog: false,
      detailItem: {},
      showUploadedFiles: false
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.datatableScrollToTop('mrc-ptf-datatable')
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_PROJECTTYPICALFLOORPLANS_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.description = '';
        }, 500)
      }
      ;
      if (!val) this.editedItem = {};
      if (!val) this.activeTabSecond = 0;
      if (!val) this.showUploadedFiles = false;
    },
    project() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 4 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(PROJECTTYPICALFLOORPLANS_LIST, {
          project_id: this.project.id,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(type, item) {
      this.$store.dispatch(GET_PROJECTTYPICALFLOORPLANS_DETAILS, item.id).then((data) => {
        if (type === 'edit') {
          this.editedItem = data.records || {};
          this.dialog = !this.dialog;
          this.showUploadedFiles = true;
        } else {
          this.detailItem = data.records || {};
          this.detailDialog = !this.dialog;
        }

      });
    },
    closeDialog() {
      if (this.$store.state.uploads.uploadedStateOtherFilesSecond.length > 0) {
        this.$store.dispatch(SET_SNACK, {
          clr: "error",
          msg: "You've some uploaded data, please clear them to close the form"
        });
      } else {
        this.dialog = !this.dialog;
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_PROJECTTYPICALFLOORPLANS;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "project_id", this.project.id);
        this.$set(item, "project_typical_floor_plan_files", this.$store.state.uploads.uploadedStateOtherFilesSecond);
        if (id) {
          method = UPDATE_PROJECTTYPICALFLOORPLANS;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_PROJECTTYPICALFLOORPLANS_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {}
          this.dialog = !this.dialog
          bus.$emit("moduleFilesSecondDeletedByModal");
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete '" + item.title + "'?", clr: 'red', callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_PROJECTTYPICALFLOORPLANS, {
            'id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    getPropertyTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'Properties', is_property: 1}).then(data => {
        this.propertyTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getBrands();
      });
    },
    getBrands() {
      this.$store.dispatch(BRANDS_SIMPLE_LIST, {type: 'Projects'}).then(data => {
        this.brandsFilter = data.records.brandsList;
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.projecttypicalfloorplans.errors
    })
  }
};
</script>