<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <ViewsSelection
                  @getViews="getViews(false)"
                  :viewsFilter="viewsFilter"
                  :viewsIdsFilter="viewsIdsFilter"/>
              <SpecialClassificationsSelection
                  @getSpecialClassifications="getSpecialClassifications(false)"
                  :scFilter="specialClassificationsFilter"
                  :scIdsFilter="specialClassificationsIdsFilter"/>
              <AmenitiesSelection
                  @getAmenities="getAmenities(false)"
                  :amenitiesFilter="amenitiesFilter"
                  :amenitiesIdsFilter="amenitiesIdsFilter"/>
              <RoadLocationsSelection
                  @getRoadLocations="getRoadLocations"
                  :rlFilter="rlFilter"
                  :rlIdsFilter="rlIdsFilter"/>
              <v-dialog persistent v-model="movePrDialog" max-width="1200">
                <ProjectSimpleData @refreshMainTable="refreshData(true)" @closePrDialog="movePrDialog = false"/>
              </v-dialog>
              <v-dialog persistent v-model="moveDialog" max-width="700">
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="moveDialog = false; itemFromMove = {}; itemToMove = {};"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Move {{ itemFromMove.title }}'s Details</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!itemToMove.project_id && !itemToMove.development_id"
                          dark
                          text
                          @click="updateStatus(itemToMove, itemFromMove.id, 'move_project_details')"
                      >
                        Move
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              :disabled="itemToMove.development_id"
                              v-model="itemToMove.project_id"
                              :items="projectsFilter"
                              item-text="title"
                              item-value="id"
                              hide-details
                              outlined
                              deletable-chips
                              dense
                              chips
                              :loading="isProjectsLoaded"
                              small-chips
                              label="Move to Project (Minimum 3 Characters Required)"
                              @keyup="projectsFilterKeyUpWDS"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                                    <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              :disabled="itemToMove.project_id"
                              v-model="itemToMove.development_id"
                              :items="developmentsFilter"
                              item-text="commercial_title"
                              item-value="id"
                              outlined
                              dense
                              deletable-chips
                              chips
                              :loading="isDevelopmentsLoaded"
                              small-chips
                              hide-details
                              label="Move to Development (min. 3 characters required)"
                              @keyup="developmentsFilterKeyUp"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dupDialog" max-width="700">
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="dupDialog = false; dupItem = '';"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Duplicate Project</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!dupItem"
                          dark
                          text
                          @click="duplicateProject(dupItem)"
                      >
                        Duplicate
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              v-model="dupItem"
                              :items="projectsFilter"
                              item-text="title"
                              item-value="id"
                              hide-details
                              clearable
                              outlined
                              deletable-chips
                              dense
                              chips
                              :loading="isProjectsLoaded"
                              small-chips
                              label="Select Project To Duplicate (Minimum 3 Characters Required)"
                              @keyup="projectsFilterKeyUp"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                                    <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="treeDialog" fullscreen>
                <v-card>
                  <ProjectDetails @editProjectFD="editProjectFD"
                                  :item="treeItem"
                                  @closeTreeDialog="treeDialog = false"/>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialog" fullscreen
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <!--                    <v-btn-->
                    <!--                        v-if="$store.state.auth.user.type === 1"-->
                    <!--                        color="#00004d"-->
                    <!--                        dark-->
                    <!--                        class="ml-auto ma-3 mr-1"-->
                    <!--                        @click="movePrDialog = true"-->
                    <!--                    >-->
                    <!--                      Move Projects-->
                    <!--                    </v-btn>-->
                    <v-btn
                        color="#00004d"
                        dark
                        :class="$store.state.auth.user.type === 1 ? 'ml-auto ma-3 ml-0 mr-1' : 'ml-auto ma-3 mr-1'"
                        @click="refreshData(true)"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                        color="#00004d"
                        dark
                        class="ma-3 ml-0 mr-1"
                        @click="dupDialog = true"
                    >
                      Duplicate
                      <v-icon small right>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn v-if="hasPermission('projects.create')" color="#00004d"
                           dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon small right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
                      <span v-else>Create Project</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-tabs v-model="activeTab" background-color="#002366" left dark>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Basic Details</v-tab>
                    <v-tab>Important Dates</v-tab>
                    <v-tab>Description</v-tab>
                    <v-tab>Location</v-tab>
                    <v-tab>Media</v-tab>
                    <v-tab v-if="editedItem.id">Typical Floor Plans</v-tab>
                    <v-tab v-if="editedItem.id">Internal Layouts</v-tab>
                    <v-tab v-if="editedItem.id">External Layouts</v-tab>
                    <v-tab>Social Media Handler</v-tab>
                    <v-tab>SEO Handler</v-tab>
                    <v-tab>Bank Details</v-tab>
                    <v-tab v-if="editedItem.id">Project Details MP</v-tab>
                    <v-tab v-if="editedItem.id">Project Details PM</v-tab>
                    <v-tab v-if="editedItem.id">Payment Plans</v-tab>
                  </v-tabs>
                  <v-tabs-items touchless v-model="activeTab">
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                          >
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.title" :counter="200"
                                              :rules="validationRules.titleRules"
                                              label="MRC Title*"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.mp_title" :counter="200"
                                              label="MP Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.pm_title" :counter="200"
                                              label="PM Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.bayut_title" :counter="200"
                                              label="Bayut Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.dubizzle_title" :counter="200"
                                              label="Dubizzle Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.pf_title" :counter="200"
                                              label="PF Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense
                                              v-model="editedItem.project_no" :counter="30"
                                              label="Project Number"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.plot_no" :counter="30"
                                              label="Plot Number"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.configuration" :counter="30"
                                              label="Project Configuration"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.no_of_units" :counter="30"
                                              label="Number Of Units"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.no_of_sold_units" :counter="30"
                                              label="Number Of Sold Units"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.no_of_remaining_units" :counter="30"
                                              label="Number Of Remaining Units"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.no_of_floor" :counter="30"
                                              label="Number Of Floor"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6">
                                <v-row>
                                  <v-col cols="12" sm="4">
                                    <v-select
                                        clearable
                                        outlined
                                        dense
                                        :items="unitsFilter"
                                        v-model="plotAreaUnit"
                                        item-text="title"
                                        item-value="value"
                                        label="Unit"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="8" v-if="plotAreaUnit !== 'Dimensions'">
                                    <v-text-field clearable
                                                  outlined
                                                  dense
                                                  v-model="editedItem.plot_area"
                                                  :counter="10"
                                                  @keypress="isNumber($event)"
                                                  label="Plot Area"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4"
                                         v-if="plotAreaUnit && plotAreaUnit === 'Dimensions'">
                                    <v-text-field clearable
                                                  outlined
                                                  dense
                                                  v-model="editedItem.plot_area_width"
                                                  :counter="10"
                                                  @keypress="isNumber($event)"
                                                  label="Plot Area Width"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4"
                                         v-if="plotAreaUnit && plotAreaUnit === 'Dimensions'">
                                    <v-text-field clearable
                                                  outlined
                                                  dense
                                                  v-model="editedItem.plot_area_length"
                                                  :counter="10"
                                                  @keypress="isNumber($event)"
                                                  label="Plot Area Length"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-row>
                                  <v-col cols="12" sm="4">
                                    <v-select
                                        clearable
                                        outlined
                                        dense
                                        :items="unitsFilter"
                                        v-model="builtUpAreaUnit"
                                        item-text="title"
                                        item-value="value"
                                        label="Unit"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="8" v-if="builtUpAreaUnit !== 'Dimensions'">
                                    <v-text-field clearable
                                                  outlined
                                                  dense
                                                  @keypress="isNumber($event)"
                                                  v-model="editedItem.built_up_area" :counter="10"
                                                  label="Built Up Area"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4"
                                         v-if="builtUpAreaUnit && builtUpAreaUnit === 'Dimensions'">
                                    <v-text-field clearable
                                                  outlined
                                                  dense
                                                  v-model="editedItem.built_up_area_width"
                                                  :counter="10"
                                                  @keypress="isNumber($event)"
                                                  label="Built Up Area Width"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="4"
                                         v-if="builtUpAreaUnit && builtUpAreaUnit === 'Dimensions'">
                                    <v-text-field clearable
                                                  outlined
                                                  dense
                                                  v-model="editedItem.built_up_area_length"
                                                  :counter="10"
                                                  @keypress="isNumber($event)"
                                                  label="Built Up Area Length"></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              @keypress="isNumber($event)"
                                              @paste="onPaste($event)"
                                              dense v-model="editedItem.status_percentage" maxLength="10" :counter="10"
                                              label="Status Percentage %"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.development_id"
                                    :items="developmentsFilter"
                                    item-text="commercial_title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isDevelopmentsLoaded"
                                    small-chips
                                    label="Master Development (min. 3 characters required)"
                                    @keyup="developmentsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank"
                                       href="/developments-management/master-developments?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                          <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                            fa-angle-double-right
                                          </v-icon>

                                          <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                          <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                            fa-angle-double-right
                                          </v-icon>

                                          <span
                                              v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                          <v-icon
                                              v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                              x-small class="ml-1 mr-1">fas fa-angle-double-right
                                          </v-icon>

                                          <span
                                              v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                          <v-icon
                                              v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                              x-small class="ml-1 mr-1">fas fa-angle-double-right
                                          </v-icon>

                                          <span
                                              v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                          <v-icon
                                              v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                              x-small class="ml-1 mr-1">fas fa-angle-double-right
                                          </v-icon>

                                          <span
                                              v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                          <v-icon
                                              v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                              x-small class="ml-1 mr-1">fas fa-angle-double-right
                                          </v-icon>

                                          <span
                                              v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                          <v-icon
                                              v-if="data.item.parent_development"
                                              x-small class="ml-1 mr-1">fas fa-angle-double-right
                                          </v-icon>

                                          <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.project_type_id"
                                    :items="projectTypesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Project Type"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getProjectTypes(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/setup/development-types?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.completion_status_id"
                                    :items="completionStatusesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Completion Status"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getCompletionStatuses(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/setup/completion-status?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.life_cycle_id"
                                    :items="lifeCyclesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Life Cycle"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getLifeCycles(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/setup/life-cycles?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.property_type_ids"
                                    :items="propertyTypesFilter"
                                    item-text="full_title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Property Type(s)"
                                    multiple
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getPropertyTypes(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/setup/property-types?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row class="mb-8">
                              <v-col cols="12" sm="3">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.viewsDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedViewIds.length > 0"
                                        v-html="$store.state.selections.selectedViewIds.length + ' View(s) Selected'"
                                  ></span>
                                  <span v-else>Select Views</span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.scDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedSCIds.length > 0"
                                        v-html="$store.state.selections.selectedSCIds.length + ' Special Classification(s) Selected'"
                                  ></span>
                                  <span v-else>Select Special Classifications</span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.amenitiesDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedAmenityIds.length > 0"
                                        v-html="$store.state.selections.selectedAmenityIds.length + ' Amenities/Feature(s) Selected'"
                                  ></span>
                                  <span v-else>Select Amenities/Features</span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.rlDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedRLIds.length > 0"
                                        v-html="$store.state.selections.selectedRLIds.length + ' Road Location(s) Selected'"
                                  ></span>
                                  <span v-else>Select Road Locations</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.developer_id"
                                    :items="developersFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isDevelopersLoaded"
                                    small-chips
                                    label="Developer (min. 3 characters required)"
                                    @keyup="developersFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.main_contractor_ids"
                                    :items="mainContractorsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isMainContractorsLoaded"
                                    small-chips
                                    multiple
                                    label="Main Contractor(s) (min. 3 characters required)"
                                    @keyup="mainContractorsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.sub_contractor_ids"
                                    :items="subContractorsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isSubContractorsLoaded"
                                    small-chips
                                    multiple
                                    label="Sub Contractor(s) (min. 3 characters required)"
                                    @keyup="subContractorsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.architectural_consultant_ids"
                                    :items="architecturalConsultantsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isArchitecturalConsultantsLoaded"
                                    small-chips
                                    multiple
                                    label="Architectural Consultant(s) (min. 3 characters required)"
                                    @keyup="architecturalConsultantsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.architect_ids"
                                    :items="architectsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isArchitectsLoaded"
                                    small-chips
                                    multiple
                                    label="Architect(s) (min. 3 characters required)"
                                    @keyup="architectsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.cost_consultant_ids"
                                    :items="costConsultantsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isCostConsultantsLoaded"
                                    small-chips
                                    multiple
                                    label="Cost Consultant(s) (min. 3 characters required)"
                                    @keyup="costConsultantsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.piling_contractor_ids"
                                    :items="pilingContractorsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isPilingContractorsLoaded"
                                    small-chips
                                    multiple
                                    label="Piling Contractor(s) (min. 3 characters required)"
                                    @keyup="pilingContractorsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.mep_contractor_ids"
                                    :items="mepContractorsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isMepContractorsLoaded"
                                    small-chips
                                    multiple
                                    label="Mep Contractor(s) (min. 3 characters required)"
                                    @keyup="mepContractorsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.project_manager_ids"
                                    :items="projectManagersFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isProjectManagersLoaded"
                                    small-chips
                                    multiple
                                    label="Project Manager(s) (min. 3 characters required)"
                                    @keyup="projectManagersFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date"
                                            v-model="editedItem.launch_date"
                                            label="Launch Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date" v-model="editedItem.registration_date"
                                            label="Registration Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date" v-model="editedItem.construction_started_date"
                                            label="Construction Started Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date" v-model="editedItem.anticipated_completion_date"
                                            label="Anticipated completion date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date" v-model="editedItem.handover_date"
                                            label="Handover date"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <!--                                <h5>Description</h5>-->
                                <!--                                <br>-->
                                <!--                                <quill-editor-->
                                <!--                                    style="height: 300px"-->
                                <!--                                    v-model="editedItem.description"-->
                                <!--                                />-->
                                <editor
                                    :api-key="tinyKey"
                                    ref="rc_editor"
                                    v-model="editedItem.description"
                                    :init="tinyEditorInit"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <gmap-autocomplete
                                    class="gmap-input"
                                    :value="mapInput"
                                    :select-first-on-enter="true"
                                    @place_changed="initMarker"></gmap-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-btn color="#00004d"
                                       dark
                                       class="ml-auto"
                                       style="width:100%"
                                       @click="addLocationMarker">Search
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.location"
                                              label="Location"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field readonly v-model="this.$store.state.projects.latitude"
                                              label="Latitude"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field readonly v-model="this.$store.state.projects.longitude"
                                              label="Longitude"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <gmap-map
                                    :zoom="20"
                                    :center="center"
                                    style="width:100%;  height: 400px;"
                                    :clickable="true"
                                    @click="setLatLong($event)"
                                >
                                  <gmap-marker
                                      :key="index"
                                      v-for="(m, index) in locationMarkers"
                                      :position="m.position"
                                  ></gmap-marker>
                                </gmap-map>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-tabs
                          background-color="primary" left dark>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab>Master Location</v-tab>
                        <v-tab>Gallery</v-tab>
                        <v-tab>Brochure</v-tab>
                        <v-tab>Documents</v-tab>

                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="project_files"
                              moduleFor="project_master_plan"
                              moduleTitle="Master Plan"
                              acceptedFiles="image/jpeg, image/png, application/pdf"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="projects"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="project_files"
                              moduleKey="project_id"
                              moduleTitle="Master Plan"
                              moduleOfFolder="projects"
                          ></ModuleUploadedFiles>
                        </v-tab-item>

                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="project_files"
                              moduleTitle="Gallery"
                              moduleFor="project_gallery"
                              acceptedFiles="image/jpeg, image/png"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="projects"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="project_files"
                              moduleKey="project_id"
                              moduleTitle="Gallery"
                              moduleOfFolder="projects"
                          ></ModuleUploadedFiles>
                        </v-tab-item>

                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="project_files"
                              moduleTitle="Brochure"
                              moduleFor="project_brochure"
                              acceptedFiles="application/pdf"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="projects"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="project_files"
                              moduleKey="project_id"
                              moduleTitle="Brochure"
                              moduleOfFolder="projects"
                          ></ModuleUploadedFiles>
                        </v-tab-item>

                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="project_files"
                              moduleTitle="Documents"
                              moduleFor="project_documents"
                              acceptedFiles="image/jpeg, image/png, application/pdf"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="projects"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="project_files"
                              moduleKey="project_id"
                              moduleTitle="Documents"
                              moduleOfFolder="projects"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                      </v-tabs>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectTypicalFloorPlans
                          :moduleTitle="editedItem.title + '\'s Typical Floor Plans'"
                          :project="editedItem"
                      ></ProjectTypicalFloorPlans>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectInternalLayouts
                          :moduleTitle="editedItem.title + '\'s Internal Layouts'"
                          :project="editedItem"
                      ></ProjectInternalLayouts>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectExternalLayouts
                          :moduleTitle="editedItem.title + '\'s External Layouts'"
                          :project="editedItem"
                      ></ProjectExternalLayouts>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="youtube_videos_links"
                                moduleTitle="Youtube Videos"
                                :moduleLinksOld="editedItem.youtube_videos_links ? editedItem.youtube_videos_links : editedItem.youtube_videos_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="youtube_playlist_links"
                                moduleTitle="Youtube Playlist"
                                :moduleLinksOld="editedItem.youtube_playlist_links ? editedItem.youtube_playlist_links : editedItem.youtube_playlist_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="virtual_tour_links"
                                moduleTitle="Virtual Tour"
                                :moduleLinksOld="editedItem.virtual_tour_links ? editedItem.virtual_tour_links : editedItem.virtual_tour_links === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="project_links"
                                moduleTitle="Website URLS"
                                :moduleLinksOld="editedItem.website_urls ? editedItem.website_urls : editedItem.website_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="instagram_urls"
                                moduleTitle="Instagram URLS"
                                :moduleLinksOld="editedItem.instagram_urls ? editedItem.instagram_urls : editedItem.instagram_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="facebook_urls"
                                moduleTitle="Facebook URLS"
                                :moduleLinksOld="editedItem.facebook_urls ? editedItem.facebook_urls : editedItem.facebook_urls === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="twitter_urls"
                                moduleTitle="Twitter URLS"
                                :moduleLinksOld="editedItem.twitter_urls ? editedItem.twitter_urls : editedItem.twitter_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="project_links"
                                moduleFor="linkedin_urls"
                                moduleTitle="Linkedin URLS"
                                :moduleLinksOld="editedItem.linkedin_urls ? editedItem.linkedin_urls : editedItem.linkedin_urls === []"
                            ></ModuleLinks>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense v-model="editedItem.meta_title"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Meta title"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense v-model="editedItem.meta_keywords"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Meta keywords"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense v-model="editedItem.focus_keywords"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Focus keywords"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-textarea
                                    clearable
                                    outlined
                                    dense v-model="editedItem.meta_description"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Meta description"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ProjectBankDetails
                                moduleOf="project_bank_details"
                                moduleTitle="Escrow Bank Details"
                                :projectBankDetailsOld="editedItem.project_bank_details ? editedItem.project_bank_details : editedItem.project_bank_details === []"
                            ></ProjectBankDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectUnitDetails
                          pudType="project"
                          contact=""
                          listingId=""
                          :project="editedItem"
                      ></ProjectUnitDetails>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectUnitDetailsPm
                          :project="editedItem"
                      ></ProjectUnitDetailsPm>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectPaymentPlans
                          :moduleTitle="editedItem.title + '\'s Payment Plans'"
                          :project="editedItem"
                      ></ProjectPaymentPlans>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        :disabled="!validationRules.valid"
                        color="blue darken-1"
                        text
                        @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <div class="col-md-12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="searchForm" v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.developmentSearch"
                                :items="developmentsFilter"
                                item-text="commercial_title"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                deletable-chips
                                chips
                                :loading="isDevelopmentsLoaded"
                                small-chips
                                hide-details
                                label="Development (min. 3 characters required)"
                                @keyup="developmentsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                      <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                      <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.projectSearch"
                                :items="projectsFilter"
                                item-text="title"
                                item-value="id"
                                hide-details
                                clearable
                                outlined
                                dense
                                chips
                                :loading="isProjectsLoaded"
                                small-chips
                                label="Project (Minimum 3 Characters Required)"
                                @keyup="projectsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                      <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                      <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.development && data.item.development.parent_development"
                                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                                      <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.projectTypeSearch"
                                :items="projectTypesFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                deletable-chips
                                chips
                                small-chips
                                hide-details
                                label="Project Type"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title
                                        v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.completionStatusSearch"
                                :items="completionStatusesFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                deletable-chips
                                chips
                                small-chips
                                hide-details
                                label="Completion Status"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title
                                        v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.mdSearch"
                                :items="contactsFilter"
                                item-text="full_name"
                                item-value="id"
                                outlined
                                dense
                                deletable-chips
                                chips
                                :loading="isContactsLoaded"
                                hide-details
                                small-chips
                                label="Developer (min. 3 characters required)"
                                @keyup="contactsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <h6 v-html="data.item.full_name"></h6>
                                      <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                      <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                      <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-select :items="[{'title': 'Yes', 'value': 1},{'title': 'No', 'value': 0}]"
                                      v-model="searchFormData.ftSearch"
                                      hide-details
                                      item-text="title"
                                      item-value="value"
                                      outlined
                                      dense
                                      label="Featured"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Search
                              <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                                    class="btn btn-primary ml-auto">
                              Reset
                              <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-card-title>
                Projects ({{ addTSBVZ(totalRecords) }})
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-toolbar v-if="mainDataTableSelection.length > 0" class="mrc-fixed-bar" height="50"
                         dark
                         color="#00004d">
                <v-toolbar-items>
                  <v-checkbox class="mt-3" v-if="items.length > 0"
                              :indeterminate="(items.length > mainDataTableSelection.length) && (mainDataTableSelection.length > 0)"
                              :value="items.length === mainDataTableSelection.length"
                              @change="bulkSelectChange($event, items)"></v-checkbox>
                  <v-btn
                      dark
                      text
                      target="_blank"
                      :href="'/leads?tol=projects&refs=' + mainDataTableSelection.map( (el) => el.id).toString()"
                  >
                    Add Inquiry
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text v-if="!isLoaded">
                <v-row v-if="items.length > 0">
                  <v-col cols="12" v-for="(item, index) in items" :key="index">
                    <v-card
                        class="mx-auto"
                        outlined
                        raised
                    >
                      <v-toolbar
                          height="40"
                          dark
                          color="#00004d"
                      >
                        <v-checkbox class="mt-5"
                                    :true-value="1"
                                    :false-value="0"
                                    :value="item"
                                    v-model="mainDataTableSelection"></v-checkbox>
                        <v-toolbar-title class="hidden-sm-and-down">
                          <span v-html="item.country ? item.country.title : ''"></span>
                          <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                          <span v-html="item.city ? item.city.title : ''"></span>
                          <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                          <span
                              v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                          <v-icon
                              v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                              dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                          </v-icon>

                          <span
                              v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                          <v-icon
                              v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                              dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                          </v-icon>

                          <span
                              v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                          <v-icon
                              v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                              dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                          </v-icon>

                          <span
                              v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                          <v-icon
                              v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                              dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                          </v-icon>

                          <span
                              v-html="(item.development && item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
                          <v-icon v-if="item.development && item.development.parent_development" dark x-small
                                  class="ml-1 mr-1">fas fa-angle-double-right
                          </v-icon>

                          <span v-html="item.development ? item.development.commercial_title : ''"></span>
                          <v-icon v-if="item.development" dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                          </v-icon>

                          <span v-html="item.title"></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items class="hidden-sm-and-down">
                          <v-btn
                              v-if="item.project_details_count > 0 && $store.state.auth.user.type === 1"
                              dark
                              text
                              @click="itemFromMove = item; moveDialog = true"
                          >
                            Move Project Details
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('contacts.view')"
                              dark
                              text
                              :href="'/contacts-management/contacts?project=' + item.id"
                              target="_blank"
                          >
                            View Owners
                          </v-btn>
                          <v-btn
                              dark
                              text
                              @click="showEditDialog('detail', item)"
                          >
                            View Details
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('projects.update')"
                              dark
                              text
                              @click="syncProjectTransactions(item)"
                          >
                            Sync
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('projects.update')"
                              dark
                              text
                              @click="showEditDialog('edit', item)"
                          >
                            Edit
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('projects.archive')"
                              dark
                              text
                              @click="deleteItem(item)"
                          >
                            Archive
                          </v-btn>
                        </v-toolbar-items>
                        <v-menu
                            bottom
                            :close-on-content-click="true"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="hidden-md-and-up"
                                height="25"
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              Actions
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                                v-if="item.project_details_count > 0 && $store.state.auth.user.type === 1">
                              <v-btn
                                  text
                                  @click="itemFromMove = item; moveDialog = true"
                              >
                                Move Project Details
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('contacts.view')">
                              <v-btn
                                  text
                                  :href="'/contacts-management/contacts?project=' + item.id"
                                  target="_blank"
                              >
                                View Owners
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn
                                  text
                                  @click="showEditDialog('detail', item)"
                              >
                                View Details
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('projects.update')">
                              <v-btn
                                  text
                                  @click="syncProjectTransactions(item)"
                              >
                                Sync Transactions
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('projects.update')">
                              <v-btn
                                  text
                                  @click="showEditDialog('edit', item)"
                              >
                                Edit
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('projects.archive')">
                              <v-btn
                                  text
                                  @click="deleteItem(item)"
                              >
                                Archive
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="3">
                            <h6>Title</h6>
                            <p class="hidden-sm-and-down" v-html="item.title"></p>
                            <p class="hidden-md-and-up">
                              <span v-html="item.country ? item.country.title : ''"></span>
                              <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                              <span v-html="item.city ? item.city.title : ''"></span>
                              <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                              <span
                                  v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(item.development && item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
                              <v-icon v-if="item.development && item.development.parent_development" x-small
                                      class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span v-html="item.development ? item.development.commercial_title : ''"></span>
                              <v-icon v-if="item.development" x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span v-html="item.title"></span>
                            </p>
                            <h6>Project Type</h6>
                            <p v-html="item.project_type ? item.project_type.title : '-'"></p>
                            <h6>Project Number</h6>
                            <p v-html="item.project_no ? item.project_no : '-'"></p>
                            <h6>Project Configuration</h6>
                            <p v-html="item.configuration ? item.configuration : '-'"></p>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <h6>Number Of Units</h6>
                            <p v-html="item.no_of_units ? item.no_of_units : '-'"></p>
                            <h6>Number Of Units Sold</h6>
                            <p v-html="item.no_of_sold_units ? item.no_of_sold_units : '-'"></p>
                            <h6>Number Of Remaining Units</h6>
                            <p v-html="item.no_of_remaining_units ? item.no_of_remaining_units : '-'"></p>
                            <h6>Number Of Floor</h6>
                            <p v-html="item.no_of_floor ? item.no_of_floor : '-'"></p>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <h6>Status Percentage %</h6>
                            <p v-html="item.status_percentage ? item.status_percentage : '-'"></p>
                            <h6>Completion Status</h6>
                            <p v-html="item.completion_status ? item.completion_status.title : '-'"></p>
                            <h6>Life Cycle</h6>
                            <p v-html="item.life_cycle ? item.life_cycle.title : '-'"></p>
                            <h6>Property Types</h6>
                            <p v-html="item.property_type_titles ? item.property_type_titles : '-'"></p>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <h6>Added By</h6>
                            <p v-html="item.created_user_name ? item.created_user_name + ' at ' + formatDateAndTime(item.created_at) : '-'"></p>
                            <h6>Updated By</h6>
                            <p v-html="item.updated_user_name ? item.updated_user_name + ' at ' + formatDateAndTime(item.updated_at) : '-'"></p>
                            <h6>Featured/Trending</h6>
                            <v-switch
                                v-if="$store.state.auth.user.team_id === 1"
                                v-model="item.is_featured"
                                v-bind:color="item.is_featured === 1 ? 'success': ''"
                                item-value="value"
                                @change="updateStatus($event,item.id,'is_featured')"
                            ></v-switch>
                            <v-switch
                                v-if="$store.state.auth.user.team_id === 2"
                                v-model="item.is_featured_afs"
                                v-bind:color="item.is_featured_afs === 1 ? 'success': ''"
                                item-value="value"
                                @change="updateStatus($event,item.id,'is_featured_afs')"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!--                      <v-toolbar-->
                      <!--                          height="30"-->
                      <!--                          dark-->
                      <!--                          color="primary"-->
                      <!--                      >-->
                      <!--                        <v-toolbar-title>-->
                      <!--                          Created at:-->
                      <!--                          {{ formatDateAndTime(item.created_at) }} by-->
                      <!--                          {{ item.created_user_name }}-->
                      <!--                        </v-toolbar-title>-->
                      <!--                        <v-spacer></v-spacer>-->
                      <!--                        <v-toolbar-title>-->
                      <!--                          Updated at:-->
                      <!--                          {{ formatDateAndTime(item.updated_at) }} by-->
                      <!--                          {{ item.updated_user_name }}-->
                      <!--                        </v-toolbar-title>-->
                      <!--                      </v-toolbar>-->
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <p class="text-center"><i>No Record Found.</i></p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0 custom-datatable"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer
                      class="custom-datatable-footer"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="rowsPerPageItems"
                      :show-first-last-page="true"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  PROJECTS_LIST,
  CREATE_PROJECTS,
  UPDATE_PROJECTS,
  CLEAR_PROJECTS_ERRORS,
  UPDATE_PROJECTS_STATUS,
  DELETE_PROJECTS,
  DUPLICATE_PROJECT,
  GET_PROJECTS_DETAILS, SYNC_PROJECT_TRANSACTIONS,
} from "@/core/services/store/projects.module";
import ViewsSelection from "@/view/pages/realstate/selections/ViewsSelection";
import SpecialClassificationsSelection from "@/view/pages/realstate/selections/SpecialClassificationsSelection";
import AmenitiesSelection from "@/view/pages/realstate/selections/AmenitiesSelection";
import RoadLocationsSelection from "@/view/pages/realstate/selections/RoadLocationsSelection";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import ModuleLinks from "@/view/pages/realstate/media/ModuleLinks";
import ProjectDetails from "@/view/pages/realstate/projects/inc/ProjectDetails";
import ProjectUnitDetails from "@/view/pages/realstate/projects/inc/ProjectUnitDetails";
import ProjectUnitDetailsPm from "@/view/pages/realstate/projects/inc/ProjectUnitDetailsPm";
import ProjectBankDetails from "@/view/pages/realstate/projects/inc/ProjectBankDetails";
import ProjectTypicalFloorPlans from "@/view/pages/realstate/projects/inc/ProjectTypicalFloorPlans";
import ProjectPaymentPlans from "@/view/pages/realstate/projects/inc/ProjectPaymentPlans";
import ProjectSimpleData from "@/view/pages/realstate/projects/inc/ProjectSimpleData";
import ProjectInternalLayouts from "@/view/pages/realstate/projects/inc/ProjectInternalLayouts";
import ProjectExternalLayouts from "@/view/pages/realstate/projects/inc/ProjectExternalLayouts";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {COMPLETIONSTATUSES_SIMPLE_LIST} from "@/core/services/store/completion_statuses.module";
import {bus} from "@/main";
import {LIFECYCLES_SIMPLE_LIST} from "@/core/services/store/lifecycles.module";
import {AMENITIES_SIMPLE_LIST} from "@/core/services/store/amenities.module";
import {ROADLOCATIONS_SIMPLE_LIST} from "@/core/services/store/road_locations.module";
import {CONTACTS_SIMPLE_LIST} from "@/core/services/store/contacts.module";
import {DEVELOPMENTS_SIMPLE_LIST} from "@/core/services/store/developments.module";
import {VIEWS_SIMPLE_LIST} from "@/core/services/store/views.module";
import {SPECIALCLASSIFICATIONS_SIMPLE_LIST} from "@/core/services/store/specialclassifications.module";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";
import Swal from "sweetalert2";

export default {
  name: 'projects',
  components: {
    ModuleMedia,
    ModuleUploadedFiles,
    ModuleLinks,
    ProjectUnitDetails,
    ProjectUnitDetailsPm,
    ProjectBankDetails,
    ProjectTypicalFloorPlans,
    ProjectInternalLayouts,
    ProjectDetails,
    ViewsSelection,
    SpecialClassificationsSelection,
    RoadLocationsSelection,
    AmenitiesSelection,
    ProjectSimpleData,
    ProjectPaymentPlans,
    ProjectExternalLayouts
  },
  data() {
    return {
      mapInput: '',
      center: {},
      locationMarkers: [],
      existingPlace: null,
      headers: [
        {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: false},
        {text: 'Country', value: 'country.title', name: 'country.title', filterable: false, sortable: false},
        {text: 'City', value: 'city.title', name: 'city.title', filterable: false, sortable: false},
        {
          text: 'Project Type',
          value: 'project_type.title',
          name: 'project_type.title',
          filterable: false,
          sortable: false
        },
        {text: "Created", value: "created_at", name: "created_at", filterable: false, sortable: true},
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      isLoadedContacts: false,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      projectTypesFilter: [],
      propertyTypesFilter: [],
      completionStatusesFilter: [],
      lifeCyclesFilter: [],
      rlFilter: [],
      rlIdsFilter: [],
      developmentsFilter: [],
      isDevelopmentsLoaded: false,
      developersFilter: [],
      isDevelopersLoaded: false,
      mainContractorsFilter: [],
      isMainContractorsLoaded: false,
      subContractorsFilter: [],
      isSubContractorsLoaded: false,
      architecturalConsultantsFilter: [],
      isArchitecturalConsultantsLoaded: false,
      architectsFilter: [],
      isArchitectsLoaded: false,
      costConsultantsFilter: [],
      isCostConsultantsLoaded: false,
      pilingContractorsFilter: [],
      isPilingContractorsLoaded: false,
      contactsFilter: [],
      isContactsLoaded: false,
      mepContractorsFilter: [],
      isMepContractorsLoaded: false,
      projectManagersFilter: [],
      isProjectManagersLoaded: false,
      projectsFilter: [],
      isProjectsLoaded: false,
      amenitiesFilter: [],
      amenitiesIdsFilter: [],
      viewsFilter: [],
      viewsIdsFilter: [],
      specialClassificationsFilter: [],
      specialClassificationsIdsFilter: [],
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 2) || "Must be greater than or equal to 2 characters",
          v => (v && v.length <= 200) || "Must be less than or equal to 200 characters"
        ],
        cityRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select City';
            else return true;
          }
        ],
        contactIdRules: [
          v => {
            if (!v || v.length < 1) return "Select Contact";
            else return true;
          }
        ],
        projectIdRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Project';
            else return true;
          }
        ],
        projectTypeIdRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Project Type';
            else return true;
          }
        ],
        statusIdRule: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Status';
            else return true;
          }
        ],
        commercialRules: [
          v => !!v || "Commercial Name is required",
          v => (v && v.length >= 3) || "Name must be greater than 3 characters",
          v => (v && v.length <= 30) || "Name must be less than 30 characters"
        ],
        typeRules: [
          v => !!v || "Type is required",
          v => (v && v.length >= 3) || "Type must be greater than 3 characters",
          v => (v && v.length <= 30) || "Type must be less than 30 characters"
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select Master Developer";
            else return true;
          }
        ]
      },
      items: [],
      dialog: false,
      editedItem: {},
      showUploadedFiles: false,
      plotAreaUnit: '',
      builtUpAreaUnit: '',
      searchFormData: {
        projectSearch: '',
        developmentSearch: '',
        mdSearch: '',
        ftSearch: '',
        projectTypeSearch: '',
        completionStatusSearch: ''
      },
      moveDialog: false,
      movePrDialog: false,
      dupDialog: false,
      dupItem: '',
      itemToMove: {},
      itemFromMove: {},
      treeDialog: false,
      treeItem: {},
    }
  },
  watch: {
    // params: {
    //   handler(v) {
    //     console.log(v)
    //     this.loadData().then(response => {
    //       this.items = response.items;
    //       this.totalRecords = response.total;
    //     });
    //   },
    //   deep: true
    // },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_PROJECTS_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.description = '';
        }, 500)
      }
      if (!val) this.editedItem = {};
      if (!val) this.showUploadedFiles = false;
      if (!val) this.center = {lat: 0, lng: 0};
      if (!val) this.locationMarkers = [];
      if (!val) this.mapInput = '';
      if (!val) this.$store.state.projects.latitude = '';
      if (!val) this.$store.state.projects.longitude = '';
      if (!val) this.activeTab = 0;
      if (!val) bus.$emit("moduleFilesDeletedByModal");
      if (!val) bus.$emit("moduleLinksDeletedByModal");
      if (!val) bus.$emit("projectUnitDetailsDeletedByModal");
      if (!val) bus.$emit("projectBankDetailsDeletedByModal");
      if (!val) this.$store.state.selections.selectedViewIds = []
      if (!val) this.$store.state.selections.selectedSCIds = []
      if (!val) this.$store.state.selections.selectedAmenityIds = []
      if (!val) this.$store.state.selections.selectedRLIds = []
      if (val) {
        this.plotAreaUnit = this.plotAreaUnit ? this.plotAreaUnit : this.$store.state.auth.user.measurement_unit
        this.builtUpAreaUnit = this.builtUpAreaUnit ? this.builtUpAreaUnit : this.$store.state.auth.user.measurement_unit
      } else {
        this.plotAreaUnit = ''
        this.builtUpAreaUnit = ''
      }
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Project's Management", route: "projects"},
      {title: "Projects"}
    ]);
    this.loadData().then(response => {
      this.items = response.items;
      this.totalRecords = response.total;
      this.getProjectTypes();
    });
    if (this.$route.query.hasOwnProperty('addModule')) {
      this.dialog = true;
    }
    if (this.$route.query.hasOwnProperty('project')) {
      let project = parseInt(this.$route.query.project)
      let item = {id: project}
      this.showEditDialog('detail', item)
    }
  },
  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        this.locationMarkers = [];
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng()
        };
        this.$store.state.projects.latitude = this.existingPlace.geometry.location.lat();
        this.$store.state.projects.longitude = this.existingPlace.geometry.location.lng();
        this.editedItem.location = this.existingPlace.formatted_address;
        this.mapInput = '';
        // this.editedItem.latitude = this.existingPlace.geometry.location.lat();
        // this.editedItem.longitude = this.existingPlace.geometry.location.lng();
        this.locationMarkers.push({position: marker});
        this.center = marker;
        this.existingPlace = null;
      }
    },
    setLatLong(e) {
      this.existingPlace = null;
      this.mapInput = '';
      let latLng = e.latLng.toJSON()
      this.$store.state.projects.latitude = latLng.lat;
      this.$store.state.projects.longitude = latLng.lng;
      const marker = {
        lat: latLng.lat,
        lng: latLng.lng
      };
      this.locationMarkers = [{position: marker}];
      this.center = marker;
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      this.items = [];
      this.mainDataTableSelection = [];
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 4 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(PROJECTS_LIST, {q: query, columns: this.columns}).then((res) => {
          // this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        if (loadDep) this.getProjectTypes();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(type, item) {
      this.$store.dispatch(GET_PROJECTS_DETAILS, item.id).then((data) => {
        if (type === 'edit') {
          let devArr = [];
          let developerArr = [];
          this.editedItem = data.records || {};
          this.mapInput = '';
          this.$store.state.projects.latitude = data.records.latitude ? parseFloat(data.records.latitude) : 0;
          this.$store.state.projects.longitude = data.records.longitude ? parseFloat(data.records.longitude) : 0;
          const marker = {
            lat: data.records.latitude ? parseFloat(data.records.latitude) : 0,
            lng: data.records.longitude ? parseFloat(data.records.longitude) : 0
          };
          this.locationMarkers.push({position: marker});
          this.center = marker;
          if (data.records.development) {
            devArr.push(data.records.development)
            this.developmentsFilter = devArr;
          }
          if (data.records.developer) {
            developerArr.push(data.records.developer)
            this.developersFilter = developerArr;
          }
          this.plotAreaUnit = data.records.plot_area_unit
          this.builtUpAreaUnit = data.records.built_up_area_unit
          this.mainContractorsFilter = data.records.main_contractors;
          this.subContractorsFilter = data.records.sub_contractors;
          this.architecturalConsultantsFilter = data.records.architectural_consultants;
          this.architectsFilter = data.records.architects;
          this.costConsultantsFilter = data.records.cost_consultants;
          this.pilingContractorsFilter = data.records.piling_contractors;
          this.mepContractorsFilter = data.records.mep_contractors;
          this.projectManagersFilter = data.records.project_managers;
          this.editedItem.description = data.records.description ? data.records.description : '';
          this.$store.state.selections.selectedViewIds = this.editedItem.view_ids
          this.$store.state.selections.selectedSCIds = this.editedItem.special_classification_ids
          this.$store.state.selections.selectedAmenityIds = this.editedItem.amenity_ids
          this.$store.state.selections.selectedRLIds = this.editedItem.road_location_ids
          this.dialog = true;
          this.showUploadedFiles = true;
        } else {
          this.treeItem = data.records || {};
          this.treeDialog = !this.treeDialog;
        }
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_PROJECTS;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "project_files", this.$store.state.uploads.uploadedStateOtherFiles);
        this.$set(item, "project_links", this.$store.state.uploads.uploadedStateLinks);
        this.$set(item, "project_bank_details", this.$store.state.projects.uploadedProjectBankDetails);
        this.$set(item, "latitude", this.$store.state.projects.latitude);
        this.$set(item, "longitude", this.$store.state.projects.longitude);
        this.$set(item, "plot_area_unit", this.plotAreaUnit);
        this.$set(item, "built_up_area_unit", this.builtUpAreaUnit);
        this.$set(item, "view_ids", this.$store.state.selections.selectedViewIds);
        this.$set(item, "special_classification_ids", this.$store.state.selections.selectedSCIds);
        this.$set(item, "amenity_ids", this.$store.state.selections.selectedAmenityIds);
        this.$set(item, "road_location_ids", this.$store.state.selections.selectedRLIds);
        if (id) {
          method = UPDATE_PROJECTS;
        }
        this.$store.dispatch(CLEAR_PROJECTS_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          // if (method === 'update_projects') {
          //   this.showUploadedFiles = false;
          //   bus.$emit("moduleFilesDeletedByModal");
          //   bus.$emit("moduleLinksDeletedByModal");
          //   bus.$emit("projectUnitDetailsDeletedByModal");
          //   bus.$emit("projectBankDetailsDeletedByModal");
          //   this.showEditDialog(item);
          // } else {
          //   this.dialog = false;
          // }
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.dialog = !this.dialog;
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    syncProjectTransactions(item) {
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to sync transactions and advertisements?",
        clr: 'primary',
        callback: function () {
          let id = item.id;
          this.$store.dispatch(SYNC_PROJECT_TRANSACTIONS, {
            'project_id': id
          }).then((data) => {
            Swal.fire('', data._metadata.message)
          });
        }
      });
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to archive?", clr: 'red', callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_PROJECTS, {
            'id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    duplicateProject(id) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to duplicate this project?", clr: 'red', callback: function () {
          this.$store.dispatch(DUPLICATE_PROJECT, {
            'project_id': id
          }).then((res) => {
            that.dupDialog = false
            that.dupItem = ''
            that.showEditDialog('edit', res.records)
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    getProjectTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'all', is_property: 0}).then((data) => {
        this.projectTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getCompletionStatuses()
      });
    },
    getCompletionStatuses(loadNext = true) {
      this.$store.dispatch(COMPLETIONSTATUSES_SIMPLE_LIST, {type: 'Projects'}).then((data) => {
        this.completionStatusesFilter = data.records.completionStatusesList;
        if (loadNext) this.getLifeCycles()
      });
    },
    getLifeCycles(loadNext = true) {
      this.$store.dispatch(LIFECYCLES_SIMPLE_LIST, {type: 'Projects'}).then((data) => {
        this.lifeCyclesFilter = data.records.lifeCyclesList;
        if (loadNext) this.getPropertyTypes()
      });
    },
    getPropertyTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'all', is_property: 1}).then((data) => {
        this.propertyTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getViews()
      });
    },
    getViews(loadNext = true) {
      this.$store.dispatch(VIEWS_SIMPLE_LIST, {type: 'Projects'}).then((data) => {
        this.viewsFilter = data.records.viewsList;
        let that = this;
        this.viewsFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.viewsIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getSpecialClassifications()
      });
    },
    getSpecialClassifications(loadNext = true) {
      this.$store.dispatch(SPECIALCLASSIFICATIONS_SIMPLE_LIST, {type: 'Projects'}).then((data) => {
        this.specialClassificationsFilter = data.records.specialClassificationsList;
        let that = this;
        this.specialClassificationsFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.specialClassificationsIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getAmenities()
      });
    },
    getAmenities(loadNext = true) {
      this.$store.dispatch(AMENITIES_SIMPLE_LIST, {type: 'Projects'}).then((data) => {
        this.amenitiesFilter = data.records.amenitiesList;
        let that = this;
        this.amenitiesFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.amenitiesIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getRoadLocations()
      });
    },
    getRoadLocations() {
      this.$store.dispatch(ROADLOCATIONS_SIMPLE_LIST, {type: 'Projects'}).then((data) => {
        this.rlFilter = data.records.roadLocationsList;
        let that = this;
        this.rlFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.rlIdsFilter.push(vv.id)
            })
          }
        })
      });
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.searchFormData.projectSearch = '';
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopmentsLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                q: e.target.value
              })
              .then(data => {
                that.developmentsFilter = data.records.developmentsList;
                that.isDevelopmentsLoaded = false;
              });
        }
      }, 900);
    },
    developersFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopersLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Developers',
                q: e.target.value
              })
              .then(data => {
                that.developersFilter = that.developersFilter.concat(data.records.contactsList);
                that.isDevelopersLoaded = false;
              });
        }
      }, 900);
    },
    mainContractorsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isMainContractorsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Main Contractors',
                q: e.target.value
              })
              .then(data => {
                that.mainContractorsFilter = that.mainContractorsFilter.concat(data.records.contactsList);
                that.isMainContractorsLoaded = false;
              });
        }
      }, 900);
    },
    subContractorsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isSubContractorsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Sub Contractors',
                q: e.target.value
              })
              .then(data => {
                that.subContractorsFilter = that.subContractorsFilter.concat(data.records.contactsList);
                that.isSubContractorsLoaded = false;
              });
        }
      }, 900);
    },
    architecturalConsultantsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isArchitecturalConsultantsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Architectural Consultants',
                q: e.target.value
              })
              .then(data => {
                that.architecturalConsultantsFilter = that.architecturalConsultantsFilter.concat(data.records.contactsList);
                that.isArchitecturalConsultantsLoaded = false;
              });
        }
      }, 900);
    },
    architectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isArchitectsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Architects',
                q: e.target.value
              })
              .then(data => {
                that.architectsFilter = that.architectsFilter.concat(data.records.contactsList);
                that.isArchitectsLoaded = false;
              });
        }
      }, 900);
    },
    costConsultantsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isCostConsultantsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Cost Consultants',
                q: e.target.value
              })
              .then(data => {
                that.costConsultantsFilter = that.costConsultantsFilter.concat(data.records.contactsList);
                that.isCostConsultantsLoaded = false;
              });
        }
      }, 900);
    },
    pilingContractorsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isPilingContractorsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Piling Contractors',
                q: e.target.value
              })
              .then(data => {
                that.pilingContractorsFilter = that.pilingContractorsFilter.concat(data.records.contactsList);
                that.isPilingContractorsLoaded = false;
              });
        }
      }, 900);
    },
    mepContractorsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isMepContractorsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'MEP Contractors',
                q: e.target.value
              })
              .then(data => {
                that.mepContractorsFilter = that.mepContractorsFilter.concat(data.records.contactsList);
                that.isMepContractorsLoaded = false;
              });
        }
      }, 900);
    },
    projectManagersFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isProjectManagersLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Project Managers',
                q: e.target.value
              })
              .then(data => {
                that.projectManagersFilter = that.projectManagersFilter.concat(data.records.contactsList);
                that.isProjectManagersLoaded = false;
              });
        }
      }, 900);
    },
    projectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isProjectsLoaded = true;
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: that.searchFormData.developmentSearch ? that.searchFormData.developmentSearch : 'none'
              })
              .then(data => {
                that.projectsFilter = data.records.projectsList;
                that.isProjectsLoaded = false;
              });
        }
      }, 900);
    },
    projectsFilterKeyUpWDS(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isProjectsLoaded = true;
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: 'none'
              })
              .then(data => {
                that.projectsFilter = data.records.projectsList;
                that.isProjectsLoaded = false;
              });
        }
      }, 900);
    },
    updateStatus(status, id, column) {
      this.$store.dispatch(CLEAR_PROJECTS_ERRORS);
      let data = {
        status: status,
        column: column
      };
      this.$store.dispatch(UPDATE_PROJECTS_STATUS, {'slug': id, data: data})
          .then(() => {
            if (column === 'move_project_details') {
              this.moveDialog = false
              this.itemToMove = {}
              this.itemFromMove = {}
              this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
              });
            }
          });
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) {
        this.searchFormData = {
          projectSearch: '',
          developmentSearch: '',
          mdSearch: '',
          ftSearch: '',
          projectTypeSearch: '',
          completionStatusSearch: ''
        };
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    editProjectFD(item) {
      this.showEditDialog('edit', item)
    },
    contactsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isContactsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Developers',
                q: e.target.value
              })
              .then(data => {
                that.contactsFilter = that.contactsFilter.concat(data.records.contactsList);
                that.isContactsLoaded = false;
              });
        }
      }, 900);
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.projects.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>