<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" fullscreen>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn
                        color="#00004d"
                        dark
                        class="ma-3 ml-0 mr-1"
                        @click="dupDialog = true"
                    >
                      Duplicate
                      <v-icon small right>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn color="#00004d" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon small right>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
                      <span v-else>Create Project Internal Layout</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-tabs v-model="activeTabSecond" background-color="#002366" left dark>
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>Basic Details</v-tab>
                      <v-tab>Description</v-tab>
                      <v-tab>Media</v-tab>
                      <v-tab>Social Media Handler</v-tab>
                      <v-tab>SEO Handler</v-tab>
                      <v-tab>Features</v-tab>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form
                                ref="form"
                                v-model="validationRules.valid"
                                lazy-validation
                            >
                              <v-row>
                                <v-col cols="12" sm="4">
                                  <v-text-field clearable
                                                outlined
                                                dense v-model="editedItem.title" :counter="50"
                                                :rules="validationRules.titleRules"
                                                label="Title*" required></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.no_of_bedrooms"
                                                :counter="20"
                                                label="Number Of Bedrooms"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.no_of_bathrooms"
                                                :counter="20"
                                                label="Number Of Bathrooms"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.elevation" :counter="50"
                                                label="Elevation"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-autocomplete
                                      clearable
                                      outlined
                                      dense
                                      :items="propertyTypesFilter"
                                      v-model="editedItem.property_type_id"
                                      item-text="full_title"
                                      item-value="id"
                                      label="Property Type"
                                      chips
                                      small-chips
                                      deletable-chips
                                      :append-icon="'mdi-refresh'"
                                      @click:append="getPropertyTypes(false)"
                                  >
                                    <template v-slot:append-outer>
                                      <a target="_blank" href="/setup/property-types?addModule=true">
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                      </a>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-text-field clearable
                                                outlined
                                                dense
                                                v-model="editedItem.floor_level"
                                                :counter="50"
                                                label="Floor Level"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="minPlotAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="minPlotAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.min_plot_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Minimum Plot Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="minPlotAreaUnit && minPlotAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.min_plot_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Minimum Plot Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="minPlotAreaUnit && minPlotAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.min_plot_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Minimum Plot Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="maxPlotAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="maxPlotAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.max_plot_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Maximum Plot Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="maxPlotAreaUnit && maxPlotAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.max_plot_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Maximum Plot Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="maxPlotAreaUnit && maxPlotAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.max_plot_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Maximum Plot Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="minBuiltUpAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="minBuiltUpAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.min_built_up_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Minimum Built Up Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="minBuiltUpAreaUnit && minBuiltUpAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.min_built_up_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Minimum Built Up Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="minBuiltUpAreaUnit && minBuiltUpAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.min_built_up_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Minimum Built Up Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="maxBuiltUpAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="maxBuiltUpAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.max_built_up_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Maximum Built Up Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="maxBuiltUpAreaUnit && maxBuiltUpAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.max_built_up_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Maximum Built Up Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="maxBuiltUpAreaUnit && maxBuiltUpAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.max_built_up_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Maximum Built Up Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="balconyAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="balconyAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.balcony_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Balcony Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="balconyAreaUnit && balconyAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.balcony_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Balcony Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="balconyAreaUnit && balconyAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.balcony_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Balcony Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="terraceAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="terraceAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.terrace_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Terrace Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="terraceAreaUnit && terraceAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.terrace_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Terrace Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="terraceAreaUnit && terraceAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.terrace_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Terrace Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="suiteAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="suiteAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.suite_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Suite Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="suiteAreaUnit && suiteAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.suite_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Suite Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="suiteAreaUnit && suiteAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.suite_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Suite Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col cols="12" sm="6">
                                  <v-row>
                                    <v-col cols="12" sm="4">
                                      <v-select
                                          outlined
                                          dense
                                          :items="unitsFilter"
                                          v-model="totalAreaUnit"
                                          item-text="title"
                                          item-value="value"
                                          label="Unit"
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="8" v-if="totalAreaUnit !== 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.total_area"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Total Area"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="totalAreaUnit && totalAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.total_area_width"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Total Area Width"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="4"
                                           v-if="totalAreaUnit && totalAreaUnit === 'Dimensions'">
                                      <v-text-field clearable
                                                    outlined
                                                    dense
                                                    v-model="editedItem.total_area_length"
                                                    :counter="10"
                                                    @keypress="isNumber($event)"
                                                    label="Total Area Length"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" sm="4">
                                  <v-autocomplete
                                      clearable
                                      outlined
                                      dense
                                      :items="brandsFilter"
                                      v-model="editedItem.designed_by_brand_ids"
                                      item-text="title"
                                      item-value="id"
                                      label="Designed By"
                                      chips
                                      small-chips
                                      deletable-chips
                                      multiple
                                      :append-icon="'mdi-refresh'"
                                      @click:append="getBrands(false)"
                                  >
                                    <template v-slot:append-outer>
                                      <a target="_blank" href="/setup/brands?addModule=true">
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                      </a>
                                    </template>
                                    <template v-slot:item="data">
                                      <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                      </template>
                                      <template v-else>
                                        <v-list-item-content>
                                          <v-list-item-title
                                              v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4">
                                  <v-autocomplete
                                      clearable
                                      outlined
                                      dense
                                      :items="brandsFilter"
                                      v-model="editedItem.furnished_by_brand_ids"
                                      item-text="title"
                                      item-value="id"
                                      label="Furnished By"
                                      chips
                                      small-chips
                                      deletable-chips
                                      multiple
                                      :append-icon="'mdi-refresh'"
                                      @click:append="getBrands(false)"
                                  >
                                    <template v-slot:append-outer>
                                      <a target="_blank" href="/setup/brands?addModule=true">
                                        <v-icon>mdi-plus-circle-outline</v-icon>
                                      </a>
                                    </template>
                                    <template v-slot:item="data">
                                      <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                      </template>
                                      <template v-else>
                                        <v-list-item-content>
                                          <v-list-item-title
                                              v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form>
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <h5>Description</h5>
                                  <br>
<!--                                  <quill-editor-->
<!--                                      style="height: 300px"-->
<!--                                      v-model="editedItem.description"-->
<!--                                  />-->
                                  <editor
                                      :api-key="tinyKey"
                                      ref="rc_editor"
                                      v-model="editedItem.description"
                                      :init="tinyEditorInit"
                                  />
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-tabs
                            background-color="primary" left dark>
                          <v-tabs-slider></v-tabs-slider>
                          <v-tab>Gallery</v-tab>
                          <v-tab>Brochure</v-tab>

                          <v-tab-item>
                            <ModuleMediaSecond
                                moduleType="other"
                                moduleOf="project_unit_type_files"
                                moduleTitle="Gallery"
                                moduleFor="project_internal_layout_gallery"
                                acceptedFiles="image/jpeg, image/png"
                                :moduleMultiple="true"
                                :deleteModule="true"
                                :moduleFilesOld="[]"
                                moduleOfFolder="project_unit_types"
                            ></ModuleMediaSecond>
                            <ModuleUploadedFiles
                                v-if="showUploadedFiles"
                                :moduleItem="editedItem"
                                moduleOf="project_unit_type_files"
                                moduleKey="project_unit_type_id"
                                moduleTitle="Gallery"
                                moduleOfFolder="project_unit_types"
                            ></ModuleUploadedFiles>
                          </v-tab-item>

                          <v-tab-item>
                            <ModuleMediaSecond
                                moduleType="other"
                                moduleOf="project_unit_type_files"
                                moduleTitle="Brochure"
                                moduleFor="project_internal_layout_brochure"
                                acceptedFiles="application/pdf"
                                :moduleMultiple="true"
                                :deleteModule="true"
                                :moduleFilesOld="[]"
                                moduleOfFolder="project_unit_types"
                            ></ModuleMediaSecond>
                            <ModuleUploadedFiles
                                v-if="showUploadedFiles"
                                :moduleItem="editedItem"
                                moduleOf="project_unit_type_files"
                                moduleKey="project_unit_type_id"
                                moduleTitle="Brochure"
                                moduleOfFolder="project_unit_types"
                            ></ModuleUploadedFiles>
                          </v-tab-item>
                        </v-tabs>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="youtube_videos_links"
                                  moduleTitle="Youtube Videos"
                                  :moduleLinksOld="editedItem.youtube_videos_links ? editedItem.youtube_videos_links : editedItem.youtube_videos_links === []"
                              ></ModuleLinks>

                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="youtube_playlist_links"
                                  moduleTitle="Youtube Playlist"
                                  :moduleLinksOld="editedItem.youtube_playlist_links ? editedItem.youtube_playlist_links : editedItem.youtube_playlist_links === []"
                              ></ModuleLinks>

                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="virtual_tour_links"
                                  moduleTitle="Virtual Tour"
                                  :moduleLinksOld="editedItem.virtual_tour_links ? editedItem.virtual_tour_links : editedItem.virtual_tour_links === []"
                              ></ModuleLinks>
                              <hr>
                              <ModuleLinks
                                  moduleOf="project_links"
                                  moduleTitle="Website URLS"
                                  :moduleLinksOld="editedItem.website_urls ? editedItem.website_urls : editedItem.website_urls === []"
                              ></ModuleLinks>

                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="instagram_urls"
                                  moduleTitle="Instagram URLS"
                                  :moduleLinksOld="editedItem.instagram_urls ? editedItem.instagram_urls : editedItem.instagram_urls === []"
                              ></ModuleLinks>

                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="facebook_urls"
                                  moduleTitle="Facebook URLS"
                                  :moduleLinksOld="editedItem.facebook_urls ? editedItem.facebook_urls : editedItem.facebook_urls === []"
                              ></ModuleLinks>
                              <hr>
                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="twitter_urls"
                                  moduleTitle="Twitter URLS"
                                  :moduleLinksOld="editedItem.twitter_urls ? editedItem.twitter_urls : editedItem.twitter_urls === []"
                              ></ModuleLinks>

                              <ModuleLinks
                                  moduleOf="project_unit_type_links"
                                  moduleFor="linkedin_urls"
                                  moduleTitle="Linkedin URLS"
                                  :moduleLinksOld="editedItem.linkedin_urls ? editedItem.linkedin_urls : editedItem.linkedin_urls === []"
                              ></ModuleLinks>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-form>
                              <v-row>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      clearable
                                      outlined
                                      dense v-model="editedItem.meta_title"
                                      :counter="500"
                                      name="input-7-1"
                                      label="Meta title"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      clearable
                                      outlined
                                      dense v-model="editedItem.meta_keywords"
                                      :counter="500"
                                      name="input-7-1"
                                      label="Meta keywords"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-text-field
                                      clearable
                                      outlined
                                      dense v-model="editedItem.focus_keywords"
                                      :counter="500"
                                      name="input-7-1"
                                      label="Focus keywords"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12">
                                  <v-textarea
                                      clearable
                                      outlined
                                      dense v-model="editedItem.meta_description"
                                      :counter="500"
                                      name="input-7-1"
                                      label="Meta description"
                                  ></v-textarea>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" v-if="pILFilter.length > 0">
                                <v-autocomplete
                                    :items="pILFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    cache-items
                                    label="Duplicate Features From"
                                    @change="pILFilterChange"
                                >
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <span v-html="'<b>' + data.item.title + '</b>'"></span>
                                          <span
                                              v-html="data.item.no_of_bedrooms ? ' (' + data.item.no_of_bedrooms + ' Bedroom)' : ''">
                                          </span>
                                          <span
                                              v-html="data.item.no_of_bedrooms ? ' (' + data.item.no_of_bathrooms + ' Bathroom)' : ''">
                                          </span>
                                          <span
                                              v-html="data.item.property_type ? ' (' + data.item.property_type.title + ')' : ''">
                                          </span>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <ProjectUnitTypeFeatures
                                  mode="edit"
                                  moduleOf="project_unit_type_features"
                                  moduleTitle="Features"
                                  :projectUnitTypeFeaturesOld="projectUnitTypeFeaturesOld"
                              />
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dupDialog" max-width="700">
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="dupDialog = false; dupItem = '';"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Duplicate Project Unit Type</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!dupItem"
                          dark
                          text
                          @click="duplicateProjectUnitType(dupItem)"
                      >
                        Duplicate
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              v-model="dupItem"
                              :items="projectsFilter"
                              item-text="title"
                              item-value="id"
                              dense
                              hide-details
                              clearable
                              outlined
                              deletable-chips
                              dense
                              chips
                              :loading="isProjectsLoaded"
                              small-chips
                              label="Select Project To Duplicate (Minimum 3 Characters Required)"
                              @keyup="projectsFilterKeyUp"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                                    <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog v-model="detailDialog" fullscreen>
                <v-card>
                  <ProjectInternalLayoutDetails :item="detailItem"
                                                :projectUnitTypeFeaturesOld="projectUnitTypeFeaturesOld"
                                                @closeDetailDialog="detailDialog = false" />
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-card-title>
                {{ moduleTitle }} ({{ addTSBVZ(totalRecords) }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0">
                <template v-slot:item.min_built_up_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.min_built_up_area ? item.min_built_up_area + ' ' : '-'"></span>
                    <span v-if="item.min_built_up_area" v-html="item.min_built_up_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.max_built_up_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.max_built_up_area ? item.max_built_up_area + ' ' : '-'"></span>
                    <span v-if="item.max_built_up_area" v-html="item.max_built_up_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.min_plot_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.min_plot_area ? item.min_plot_area + ' ' : '-'"></span>
                    <span v-if="item.min_plot_area" v-html="item.min_plot_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.max_plot_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.max_plot_area ? item.max_plot_area + ' ' : '-'"></span>
                    <span v-if="item.max_plot_area" v-html="item.max_plot_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.balcony_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.balcony_area ? item.balcony_area + ' ' : '-'"></span>
                    <span v-if="item.balcony_area" v-html="item.balcony_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.terrace_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.terrace_area ? item.terrace_area + ' ' : '-'"></span>
                    <span v-if="item.terrace_area" v-html="item.terrace_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.suite_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.suite_area ? item.suite_area + ' ' : '-'"></span>
                    <span v-if="item.suite_area" v-html="item.suite_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.total_area="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.total_area ? item.total_area + ' ' : '-'"></span>
                    <span v-if="item.total_area" v-html="item.total_area_unit"></span>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }} <br> by
                    {{ item.created_user_name }}
                  </div>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.updated_at) }} <br> by
                    {{ item.updated_user_name }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <v-btn
                        color="warning"
                        dark class="mr-2"
                        @click="showEditDialog('detail',item)"
                    >
                      View Details
                      <v-icon dark right small>fas fa-eye</v-icon>
                    </v-btn>
                    <v-btn color="#00004d" @click="showEditDialog('edit',item)" dark class="mr-2">
                      Edit
                      <v-icon dark right small>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn color="red" dark @click="deleteItem(item)" class="mr-2">
                      Delete
                      <v-icon dark right small>fas fa-trash</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
              <!-- this dialog is used for both create and update -->
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/main";
import {
  PROJECTUNITTYPES_LIST,
  CREATE_PROJECTUNITTYPES,
  UPDATE_PROJECTUNITTYPES,
  CLEAR_PROJECTUNITTYPES_ERRORS,
  DELETE_PROJECTUNITTYPES, DUPLICATE_PROJECTUNITTYPE, GET_PROJECTUNITTYPES_DETAILS,
} from "@/core/services/store/projectunittypes.module";
import ModuleMediaSecond from "@/view/pages/realstate/media/ModuleMediaSecond";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import ProjectUnitTypeFeatures from "@/view/pages/realstate/projects/inc/ProjectUnitTypeFeatures";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {SET_SNACK} from "@/core/services/store/snackbar.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {BRANDS_SIMPLE_LIST} from "@/core/services/store/brands.module";
import {PROJECTUNITTYPES_SIMPLE_LIST} from "@/core/services/store/projectunittypes.module";
import {PROJECTUNITTYPES_FEATURES_LIST} from "@/core/services/store/projectunittypes.module";
import ModuleLinks from "@/view/pages/realstate/media/ModuleLinks";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";
import ProjectInternalLayoutDetails from "@/view/pages/realstate/projects/inc/ProjectInternalLayoutDetails";

export default {
  name: 'project-internal-layouts',
  components: {
    ProjectInternalLayoutDetails,
    ModuleMediaSecond,
    ModuleUploadedFiles,
    ModuleLinks,
    ProjectUnitTypeFeatures
  },
  props: ['project', 'moduleTitle'],
  data() {
    return {
      headers: [
        {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: true},
        {
          text: 'Property type',
          value: 'property_type.title',
          name: 'property_type.title',
          filterable: false,
          sortable: false
        },
        {
          text: "Minimum Built Up Area",
          value: "min_built_up_area",
          name: "min_built_up_area",
          filterable: false,
          sortable: false
        },
        {
          text: "Maximum Built Up Area",
          value: "max_built_up_area",
          name: "max_built_up_area",
          filterable: false,
          sortable: false
        },
        {
          text: "Number Of Bedrooms",
          value: "no_of_bedrooms",
          name: "no_of_bedrooms",
          filterable: false,
          sortable: false
        },
        {
          text: "Number Of Bathrooms",
          value: "no_of_bathrooms",
          name: "no_of_bathrooms",
          filterable: false,
          sortable: false
        },
        {text: "Elevation", value: "elevation", name: "elevation", filterable: false, sortable: false},
        {text: "Floor Level", value: "floor_level", name: "floor_level", filterable: false, sortable: false},
        {text: "Minimum Plot Area", value: "min_plot_area", name: "min_plot_area", filterable: false, sortable: false},
        {text: "Maximum Plot Area", value: "max_plot_area", name: "max_plot_area", filterable: false, sortable: false},
        {text: "Balcony Area", value: "balcony_area", name: "balcony_area", filterable: false, sortable: false},
        {text: "Terrace Area", value: "terrace_area", name: "terrace_area", filterable: false, sortable: false},
        {text: "Suite Area", value: "suite_area", name: "suite_area", filterable: false, sortable: false},
        {text: "Total Area", value: "total_area", name: "total_area", filterable: false, sortable: false},
        {text: "Created", align: ' d-none', value: "created_at", name: "created_at", filterable: true, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than 1 characters",
          v => (v && v.length <= 50) || "Must be less than 50 characters"
        ],
      },
      pILFilter: [],
      propertyTypesFilter: [],
      brandsFilter: [],
      projectUnitTypeFeaturesOld: [],
      items: [],
      dialog: false,
      editedItem: {},
      selectedPIL: {},
      showUploadedFiles: false,
      minPlotAreaUnit: '',
      maxPlotAreaUnit: '',
      minBuiltUpAreaUnit: '',
      maxBuiltUpAreaUnit: '',
      balconyAreaUnit: '',
      terraceAreaUnit: '',
      suiteAreaUnit: '',
      totalAreaUnit: '',
      dupDialog: false,
      dupItem: '',
      projectsFilter: [],
      isProjectsLoaded: false,
      detailDialog: false,
      detailItem: {},
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_PROJECTUNITTYPES_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.description = '';
        }, 500)
      }
      if (!val) this.editedItem = {};
      if (!val) this.showUploadedFiles = false;
      if (!val) this.activeTabSecond = 0;
      if (!val) bus.$emit("moduleFilesSecondDeletedByModal");
      if (!val) bus.$emit("projectUnitTypeFeaturesDeletedByModal");
      if (!val) bus.$emit("moduleLinksDeletedByModal");
      if (!val) this.pILFilter = [];
      if (!val) this.getPIL();
      if (val) {
        this.minPlotAreaUnit = this.minPlotAreaUnit ? this.minPlotAreaUnit : this.$store.state.auth.user.measurement_unit
        this.maxPlotAreaUnit = this.maxPlotAreaUnit ? this.maxPlotAreaUnit : this.$store.state.auth.user.measurement_unit
        this.minBuiltUpAreaUnit = this.minBuiltUpAreaUnit ? this.minBuiltUpAreaUnit : this.$store.state.auth.user.measurement_unit
        this.maxBuiltUpAreaUnit = this.maxBuiltUpAreaUnit ? this.maxBuiltUpAreaUnit : this.$store.state.auth.user.measurement_unit
        this.balconyAreaUnit = this.balconyAreaUnit ? this.balconyAreaUnit : this.$store.state.auth.user.measurement_unit
        this.terraceAreaUnit = this.terraceAreaUnit ? this.terraceAreaUnit : this.$store.state.auth.user.measurement_unit
        this.suiteAreaUnit = this.suiteAreaUnit ? this.suiteAreaUnit : this.$store.state.auth.user.measurement_unit
        this.totalAreaUnit = this.totalAreaUnit ? this.totalAreaUnit : this.$store.state.auth.user.measurement_unit
      } else {
        this.minPlotAreaUnit = ''
        this.maxPlotAreaUnit = ''
        this.minBuiltUpAreaUnit = ''
        this.maxBuiltUpAreaUnit = ''
        this.balconyAreaUnit = ''
        this.terraceAreaUnit = ''
        this.suiteAreaUnit = ''
        this.totalAreaUnit = ''
      }
    },
    project() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.getPropertyTypes();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 14 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(PROJECTUNITTYPES_LIST, {
          type: '0',
          project_id: this.project.id,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getPropertyTypes();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(type, item) {
      this.$store.dispatch(GET_PROJECTUNITTYPES_DETAILS, item.id).then((data) => {
        if (type === 'edit') {
          this.editedItem = data.records || {};
          this.projectUnitTypeFeaturesOld = data.records.project_unit_type_features;
          this.minPlotAreaUnit = data.records.min_plot_area_unit;
          this.maxPlotAreaUnit = data.records.max_plot_area_unit;
          this.minBuiltUpAreaUnit = data.records.min_built_up_area_unit;
          this.maxBuiltUpAreaUnit = data.records.max_built_up_area_unit;
          this.balconyAreaUnit = data.records.balcony_area_unit;
          this.terraceAreaUnit = data.records.terrace_area_unit;
          this.suiteAreaUnit = data.records.suite_area_unit;
          this.totalAreaUnit = data.records.total_area_unit;
          this.dialog = !this.dialog;
          this.showUploadedFiles = true;
        } else {
          this.projectUnitTypeFeaturesOld = data.records.project_unit_type_features;
          this.detailItem = data.records || {};
          this.detailDialog = true;
        }
      });
    },
    closeDialog() {
      if (this.$store.state.uploads.uploadedStateOtherFilesSecond.length > 0) {
        this.$store.dispatch(SET_SNACK, {
          clr: "error",
          msg: "You've some uploaded data, please clear them to close the form"
        });
      } else {
        this.dialog = !this.dialog;
      }
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_PROJECTUNITTYPES;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "project_id", this.project.id);
        this.$set(item, "project_unit_type_files", this.$store.state.uploads.uploadedStateOtherFilesSecond);
        this.$set(item, "project_unit_type_links", this.$store.state.uploads.uploadedStateLinks);
        this.$set(item, "project_unit_type_features", this.$store.state.projectunittypes.uploadedProjectUnitTypeFeatures);
        this.$set(item, "min_plot_area_unit", this.minPlotAreaUnit);
        this.$set(item, "max_plot_area_unit", this.maxPlotAreaUnit);
        this.$set(item, "min_built_up_area_unit", this.minBuiltUpAreaUnit);
        this.$set(item, "max_built_up_area_unit", this.maxBuiltUpAreaUnit);
        this.$set(item, "balcony_area_unit", this.balconyAreaUnit);
        this.$set(item, "terrace_area_unit", this.terraceAreaUnit);
        this.$set(item, "suite_area_unit", this.suiteAreaUnit);
        this.$set(item, "total_area_unit", this.totalAreaUnit);
        this.$set(item, "type", '0');
        if (id) {
          method = UPDATE_PROJECTUNITTYPES;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_PROJECTUNITTYPES_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {}
          this.dialog = !this.dialog
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete '" + item.title + "'?", clr: 'red', callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_PROJECTUNITTYPES, {
            'iut_id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    getPropertyTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'all', is_property: 1}).then(data => {
        this.propertyTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getBrands();
      });
    },
    getBrands(loadNext = true) {
      this.$store.dispatch(BRANDS_SIMPLE_LIST, {type: 'Projects'}).then(data => {
        this.brandsFilter = data.records.brandsList;
        if (loadNext) this.getPIL();
      });
    },
    getPIL() {
      this.$store.dispatch(PROJECTUNITTYPES_SIMPLE_LIST, {project_id: this.project.id, type: '0'}).then(data => {
        this.pILFilter = data.records.projectUnitTypesList;
      });
    },
    pILFilterChange(v) {
      if (v) {
        // console.log(this.selectedPIL)
        this.$store.dispatch(PROJECTUNITTYPES_FEATURES_LIST, {put_id: v}).then(data => {
          this.projectUnitTypeFeaturesOld = data.records.projectUnitTypeFeaturesList
        });
      }
    },
    projectsFilterKeyUp(e) {
      this.projectsFilter = [];
      if (e.target.value.length >= 3) {
        this.isProjectsLoaded = true;
        this.$store
            .dispatch(PROJECTS_SIMPLE_LIST, {
              q: e.target.value,
              type: 'none'
            })
            .then(data => {
              this.projectsFilter = data.records.projectsList;
              this.isProjectsLoaded = false;
            });
      }
    },
    duplicateProjectUnitType(id) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to duplicate unit types from this project?", clr: 'red', callback: function () {
          this.$store.dispatch(DUPLICATE_PROJECTUNITTYPE, {
            'from_project_id': id,
            'to_project_id': that.project.id,
          }).then((res) => {
            that.dupDialog = false
            that.dupItem = ''
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.projectunittypes.errors
    })
  }
};
</script>