<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div>
                    <template>
                        <v-card>
                            <div class="col-md-12">
                                <v-expansion-panels accordion>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                                            <div class="text-center font-weight-bold">
                                                Search/Filters
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-form ref="searchForm" v-on:submit.prevent="hitAdvanceSearch(false)">
                                                <v-row>
                                                    <v-col cols="12" sm="3">
                                                        <v-autocomplete
                                                                outlined
                                                                dense
                                                                :items="ptFilter"
                                                                v-model="searchFormData.propertyTypeSearch"
                                                                item-text="full_title"
                                                                item-value="id"
                                                                label="Property Type"
                                                                chips
                                                                hide-details
                                                                small-chips
                                                                deletable-chips
                                                        >
                                                        </v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field
                                                                outlined
                                                                dense
                                                                v-model="searchFormData.unitNumberSearch"
                                                                label="Unit Number"
                                                                hide-details
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-autocomplete
                                                                dense outlined
                                                                clearable
                                                                hide-details
                                                                :items="bedsFilter"
                                                                v-model="searchFormData.bedSearch"
                                                                item-text="title"
                                                                item-value="value"
                                                                label="Beds"
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field :counter="10"
                                                                      maxLength="10"
                                                                      dense outlined
                                                                      hide-details
                                                                      maxlength="10"
                                                                      v-model="searchFormData.minBuaSearch"
                                                                      label="Min Built Up Area Sqft"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field :counter="10"
                                                                      maxLength="10"
                                                                      dense outlined
                                                                      hide-details
                                                                      maxlength="10"
                                                                      v-model="searchFormData.maxBuaSearch"
                                                                      label="Max Built Up Area Sqft"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field :counter="10"
                                                                      maxLength="10"
                                                                      dense outlined
                                                                      hide-details
                                                                      maxlength="10"
                                                                      v-model="searchFormData.minPaSearch"
                                                                      label="Min Plot Area Sqft"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field :counter="10"
                                                                      maxLength="10"
                                                                      dense outlined
                                                                      hide-details
                                                                      maxlength="10"
                                                                      v-model="searchFormData.maxPaSearch"
                                                                      label="Max Plot Area Sqft"></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field
                                                                outlined
                                                                dense
                                                                maxLength="20"
                                                                v-model="searchFormData.parkingSearch"
                                                                label="Parking"
                                                                hide-details
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field
                                                                outlined
                                                                dense
                                                                maxLength="20"
                                                                v-model="searchFormData.lnSearch"
                                                                label="Land Number"
                                                                hide-details
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="3">
                                                        <v-text-field
                                                                outlined
                                                                dense
                                                                maxLength="20"
                                                                v-model="searchFormData.mnSearch"
                                                                label="Makani Number"
                                                                hide-details
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" sm="6">
                                                        <button :disabled="loading" type="submit" style="width: 100%"
                                                                class="btn btn-success theme-btn ml-auto mr-2">
                                                            Search
                                                            <v-icon class="ml-2" small>fas fa-search</v-icon>
                                                        </button>
                                                    </v-col>
                                                    <v-col cols="12" sm="6">
                                                        <button :disabled="loading" style="width: 100%"
                                                                @click="hitAdvanceSearch(true)"
                                                                class="btn btn-primary ml-auto">
                                                            Reset
                                                            <v-icon class="ml-2" small>fas fa-times</v-icon>
                                                        </button>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </div>
                            <v-divider class="m-0"></v-divider>
                            <v-card-title>
                                Project Details PM ({{ addTSBVZ(totalRecords) }})
                            </v-card-title>
                            <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
                            >
                            </v-skeleton-loader>
                            <v-data-table
                                    :headers="headers"
                                    :items="items"
                                    dense
                                    fixed-header
                                    height="500"
                                    :options.sync="pagination"
                                    :server-items-length="totalRecords"
                                    :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                                    :loading="loading"
                                    v-show="!isLoaded"
                                    mobile-breakpoint="100"
                                    ref="mrc-pudpm-datatable"
                                    class="elevation-0">
                                <template v-slot:item.Makani_number="{ item }">
                                    <div class="text-truncate" v-if="item.Makani_number">
                                        <a target="_blank" v-html="item.Makani_number" :href="'https://www.makani.ae/desktop/home/map?l=E&m=' + item.Makani_number"></a>
                                    </div>
                                </template>
                                <template v-slot:item.built_up_area_calc="{ item }">
                                    <div class="text-truncate">
                                        <span v-html="item.built_up_area ? item.built_up_area + ' ' : '-'"></span>
                                        <span v-if="item.built_up_area" v-html="item.built_up_area_unit"></span>
                                    </div>
                                </template>
                                <template v-slot:item.plot_area="{ item }">
                                    <div class="text-truncate">
                                        <span v-html="item.plot_area ? item.plot_area + ' ' : '-'"></span>
                                        <span v-if="item.plot_area" v-html="item.plot_area_unit"></span>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {
    PROJECTUNITDETAILSPM_LIST,
    DELETE_PROJECTUNITDETAILSPM,
} from "@/core/services/store/projectunitdetailspm.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {
    DEVELOPMENTTYPES_SIMPLE_LIST_PM
} from "@/core/services/store/developmenttypes.module";

export default {
    name: 'project-unit-details-pm',
    props: ['project'],
    data() {
        return {
            that: this,
            headers: [
                {
                    text: "Master Development",
                    value: "Development",
                    name: "Development",
                    filterable: true,
                    sortable: false
                },
                {text: "Sub Development 1", value: "Community", name: "Community", filterable: true, sortable: false},
                {
                    text: "Sub Development 2/Project",
                    value: "Sub_community1",
                    name: "Sub_community1",
                    filterable: true,
                    sortable: false
                },
                {text: "Project", value: "Sub_community2", name: "Sub_community2", filterable: true, sortable: false},
                {text: "Beds", value: "Beds", name: "Beds", filterable: false, sortable: false},
                {
                    text: "Built Up Area Metres",
                    value: "Built_up_area_metres",
                    name: "Built_up_area_metres",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "built Up Area Sqft",
                    value: "built_up_area_sqft",
                    name: "built_up_area_sqft",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Plot Size Metres",
                    value: "Plot_size_metres",
                    name: "Plot_size_metres",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Plot Size Sqft",
                    value: "Plot_size_sq_ft",
                    name: "Plot_size_sq_ft",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Balcony Area Metres",
                    value: "Balcony_area_metres",
                    name: "Balcony_area_metres",
                    filterable: false,
                    sortable: false
                },
                {text: "Floor", value: "Floor", name: "Floor", filterable: false, sortable: false},
                {text: "Unit Type", value: "Unit_type", name: "Unit_type", filterable: false, sortable: false},
                {text: "Unit Number", value: "Unit_number", name: "Unit_number", filterable: false, sortable: false},
                {text: "Parking", value: "Parking", name: "Parking", filterable: false, sortable: false},
                {text: "Freehold", value: "Freehold", name: "Freehold", filterable: false, sortable: false},
                {text: "Leasehold", value: "Leasehold", name: "Leasehold", filterable: false, sortable: false},
                {text: "Land Number", value: "Land_number", name: "Land_number", filterable: false, sortable: false},
                {
                    text: "Makani Number",
                    value: "Makani_number",
                    name: "Makani_number",
                    filterable: false,
                    sortable: false
                },
                {
                    text: "Created",
                    align: ' d-none',
                    value: "created_at",
                    name: "created_at",
                    filterable: false,
                    sortable: true
                },
                // {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
            ],
            isLoaded: true,
            draw: 1,
            searchQuery: "",
            loading: true,
            pagination: {
                page: 1,
                rowsPerPage: 25,
                totalItems: 0,
                sortDesc: "desc"
            },
            totalRecords: 0,
            rowsPerPageItems: [25, 50, 100, 150, 200],
            headerindex: "",
            columns: [],
            propertyTypesFilter: [],
            items: [],
            searchFormData: {},
            ptFilter: []
        }
    },
    watch: {
        params: {
            handler() {
                this.loadData().then(response => {
                    this.items = response.items;
                    this.totalRecords = response.total;
                    this.datatableScrollToTop('mrc-pudpm-datatable')
                });
            },
            deep: true
        },
        project() {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        }
    },
    mounted() {
        for (var i = 0; i < this.headers.length; i++) {
            this.columns.push(
                '&columns[' + i + '][data]=' + this.headers[i].value +
                '&columns[' + i + '][name]=' + this.headers[i].name +
                '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
                '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
                '&columns[' + i + '][search][value]=' +
                '&columns[' + i + '][search][regex]=' + false
            );
        }
        this.getPropertyTypes();
    },
    methods: {
        searchQueryData: function (e) {
            if (e.keyCode === 13) {
                this.searchQuery = e.target.value;
            }
        },
        getHeaderIndex(name) {
            let that = this;
            this.headers.filter(function (ele, i) {
                if (name == ele.name) {
                    that.headerindex = i;
                }
            });
        },
        loadData() {
            this.loading = true;
            this.isLoaded = true;
            return new Promise((resolve) => {
                let params = this.params;
                params.length = params.itemsPerPage; //set how many records to fetch per page
                params.start =
                    params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
                params.draw = this.draw;
                let query = Object.keys(params).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                }).join('&');
                let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
                }).join('&');
                query += '&' + searchFormDataQuery;
                this.getHeaderIndex(params.sortBy[0]);
                query +=
                    "&search[value]=" +
                    params.query +
                    "&search[regex]=" +
                    false +
                    "&order[0][column]=" +
                    (params.sortBy.length === 0 ? 18 : this.headerindex) +
                    "&order[0][dir]=" +
                    (params.sortDesc[0] ? "asc" : "desc");
                this.$store.dispatch(PROJECTUNITDETAILSPM_LIST, {
                    project_id: this.project.id,
                    q: query,
                    columns: this.columns
                }).then((res) => {
                    this.draw++;
                    let items = res.records.data;
                    let total = res.records.recordsFiltered;
                    this.loading = !this.loading;
                    this.isLoaded = !this.isLoaded;
                    resolve({
                        items,
                        total
                    });
                });
            });
        },
        refreshData() {
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        },
        deleteItem(item) {
            let that = this;
            this.$store.dispatch(SET_CONFIRMATION, {
                title: "Are you sure?",
                msg: "You want to delete '" + item.title + "'?",
                clr: 'red',
                callback: function () {
                    let id = item.id;
                    this.$store.dispatch(DELETE_PROJECTUNITDETAILSPM, {
                        'id': id
                    }).then(() => {
                        that.loadData().then(response => {
                            that.items = response.items;
                            that.totalRecords = response.total;
                        });
                    });
                }
            });
        },
        getPropertyTypes(loadNext = true) {
            this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST_PM).then(data => {
                this.ptFilter = data.records.developmentTypesList;
            });
        },
        hitAdvanceSearch(reset = false) {
            this.pagination.page = 1
            if (reset) {
                this.searchFormData = {};
            }
            this.loadData().then(response => {
                this.items = response.items;
                this.totalRecords = response.total;
            });
        },
    },
    computed: {
        params() {
            return {
                ...this.pagination,
                query: this.searchQuery
            };
        },
        ...mapState({
            errors: state => state.projectunitdetailspm.errors
        })
    }
};
</script>