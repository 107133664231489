<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Life Cycle
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.life_cycle ? project.life_cycle.title : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Project Number
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.project_no ? project.project_no : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Floors
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.no_of_floor ? project.no_of_floor : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Number of Units
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.no_of_units ? project.no_of_units : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Number of Sold Units
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.no_of_sold_units ? project.no_of_sold_units : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Project Configuration
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.configuration ? project.configuration : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Total Sales
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.total_sales ? project.total_sales : '0' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              Total Transactions
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.total_transactions ? project.total_transactions : '0' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              YOY
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.yoy ? project.yoy + ' %' : '-' }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="bg-light-success text-center pt-3 pb-3">
            <p class="font-weight-bold font-size-h3 mb-3">
              MOM
            </p>
            <p class="font-weight-bold font-size-h6 m-0">
              {{ project.mom ? project.mom + ' %' : '-' }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pt-0">
            Pricing Summary
          </v-card-title>
          <div class="bg-white">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    No. Of Beds
                  </th>
                  <th class="text-left">
                    Total Units
                  </th>
                  <th class="text-left">
                    Min Built up Area
                  </th>
                  <th class="text-left">
                    Max Built up Area
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, i) in project.pricing_summary"
                    :key="i"
                >
                  <td>{{ item.beds }}</td>
                  <td>{{ item.count }}</td>
                  <td>{{ item.min_built_up_area_sqft }}</td>
                  <td>{{ item.max_built_up_area_sqft }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'project-summary',
  props: ['project'],
  data() {
    return {}
  },
  watch: {
    project() {
      // this.loadData().then(response => {
      //   this.items = response.items;
      //   this.totalRecords = response.total;
      // });
    }
  },
  mounted() {},
  methods: {
  },
  computed: {
    ...mapState({
      errors: state => state.projects.errors
    })
  }
};
</script>