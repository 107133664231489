<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="dialog" fullscreen>
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn color="#00004d" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon small>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
                      <span v-else>Create Project Payment Plan</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-form
                          ref="form"
                          v-model="validationRules.valid"
                          lazy-validation
                      >
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-text-field clearable
                                          outlined
                                          dense v-model="editedItem.title" :counter="300" :maxLength="300"
                                          :rules="validationRules.titleRules"
                                          label="Title*" required></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                                outlined
                                dense
                                v-model="editedItem.down_payment"
                                :counter="3"
                                :maxLength="3"
                                @keyup="pahKeyup($event)"
                                @keypress="isNumber($event)"
                                :rules="validationRules.valueRules"
                                label="Down Payment* (%)"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                                outlined
                                dense
                                v-model="editedItem.payment_at_handover"
                                :counter="3"
                                :maxLength="3"
                                @keyup="pahKeyup($event)"
                                @keypress="isNumber($event)"
                                :rules="validationRules.valueRules"
                                label="Payment At Handover* (%)"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                                outlined
                                dense
                                v-model="editedItem.payment_after_handover"
                                :counter="3"
                                :maxLength="3"
                                @keyup="pahKeyup($event)"
                                @keypress="isNumber($event)"
                                label="Payment After Handover* (%)"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                                style="color: black"
                                outlined
                                dense
                                readonly
                                v-model="editedItem.payment_during_construction"
                                :maxLength="3"
                                @keypress="isNumber($event)"
                                label="Payment During Construction (%)"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                                type="date"
                                outlined
                                dense
                                v-model="editedItem.start_date"
                                @keypress="isNumber($event)"
                                :rules="validationRules.selectRule"
                                label="Start Date*"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row
                            v-if="editedItem.payment_during_construction && editedItem.payment_during_construction > 0">
                          <v-col cols="12">
                            <h1>Distribute Payment During Construction {{
                                editedItem.payment_during_construction
                              }}%</h1>
                          </v-col>
                          <ProjectPaymentPlanDistribution
                              :frequencies="freFilter"
                              :projectPaymentPlanDistributionOld="editedItem.project_payment_plan_distribution_pp ? editedItem.project_payment_plan_distribution_pp : editedItem.project_payment_plan_distribution_pp === []"/>
                        </v-row>
                        <v-row v-if="editedItem.payment_after_handover && editedItem.payment_after_handover > 0">
                          <v-col cols="12">
                            <h1>Distribute Payment After Handover {{ editedItem.payment_after_handover }}%</h1>
                          </v-col>
                          <ProjectPaymentPlanDistributionPp
                              :frequencies="freFilter"
                              :projectPaymentPlanDistributionOld="editedItem.project_payment_plan_distribution_phpp ? editedItem.project_payment_plan_distribution_phpp : editedItem.project_payment_plan_distribution_phpp === []"/>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <v-card-title>
                {{ moduleTitle }} ({{ addTSBVZ(totalRecords) }})
                <v-spacer></v-spacer>
                <v-text-field
                    @keydown.enter="searchQueryData"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :item-class="itemRowBackground"
                  :server-items-length="totalRecords"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  ref="mrc-ptf-datatable"
                  class="elevation-0">
                <template v-slot:item.start_date="{ item }">
                  <div class="text-truncate">
                    {{ formatMomentDate(item.start_date) }}
                  </div>
                </template>
                <template v-slot:item.distributed="{ item }">
                  <div class="text-truncate">
                    <span v-html="item.distributed + '%'"></span>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.created_at) }}
                  </div>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-switch
                      v-model="item.status"
                      v-bind:color="item.status === 1 ? 'success': ''"
                      item-value="value"
                      @change="updateStatus($event,item.id,'status')"
                  ></v-switch>
                </template>
                <template v-slot:item.updated_at="{ item }">
                  <div class="text-truncate">
                    {{ formatDateAndTime(item.updated_at) }}
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="text-truncate">
                    <!--                    <v-btn-->
                    <!--                        color="warning"-->
                    <!--                        dark class="mr-2"-->
                    <!--                        @click="showEditDialog('detail',item)"-->
                    <!--                    >-->
                    <!--                      View Details-->
                    <!--                      <v-icon dark right small>fas fa-eye</v-icon>-->
                    <!--                    </v-btn>-->
                    <v-btn small color="#00004d" @click="showEditDialog('edit',item)" dark class="mr-2">
                      <v-icon dark small>fas fa-edit</v-icon>
                    </v-btn>
                    <v-btn small color="red" dark @click="deleteItem(item)" class="mr-2">
                      <v-icon dark small>fas fa-trash</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  CLEAR_PROJECTPAYMENTPLANS_ERRORS,
  CREATE_PROJECTPAYMENTPLANS,
  DELETE_PROJECTPAYMENTPLANS,
  GET_PROJECTPAYMENTPLANS_DETAILS,
  PROJECTPAYMENTPLANS_LIST,
  UPDATE_PROJECTPAYMENTPLANS, UPDATE_PROJECTPAYMENTPLANS_STATUS,
} from "@/core/services/store/projectpaymentplans.module";
import ModuleMediaSecond from "@/view/pages/realstate/media/ModuleMediaSecond";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import ProjectPaymentPlanDistribution from "@/view/pages/realstate/projects/inc/ProjectPaymentPlanDistribution";
import {bus} from "@/main";
import ProjectPaymentPlanDistributionPp from "@/view/pages/realstate/projects/inc/ProjectPaymentPlanDistributionPP";
import {FREQUENCIES_PP_SIMPLE_LIST} from "@/core/services/store/frequencies.module";

export default {
  name: 'project-payment-plans',
  components: {
    ProjectPaymentPlanDistributionPp,
    ProjectPaymentPlanDistribution,
    ModuleMediaSecond,
    ModuleUploadedFiles,
  },
  props: ['project', 'moduleTitle'],
  data() {
    return {
      headers: [
        {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: false},
        {text: 'Distributed', value: 'distributed', name: 'distributed', filterable: false, sortable: false},
        {text: 'Down Payment', value: 'down_payment', name: 'down_payment', filterable: false, sortable: false},
        {
          text: 'Payment At Handover',
          value: 'payment_at_handover',
          name: 'payment_at_handover',
          filterable: false,
          sortable: false
        },
        {
          text: 'Payment After Handover',
          value: 'payment_after_handover',
          name: 'payment_after_handover',
          filterable: false,
          sortable: false
        },
        {
          text: 'Payment During Construction',
          value: 'payment_during_construction',
          name: 'payment_during_construction',
          filterable: false,
          sortable: false
        },
        {text: 'Start Date', value: 'start_date', name: 'start_date', filterable: false, sortable: false},
        {text: 'Status', value: 'status', name: 'status', filterable: false, sortable: false},
        {text: "Created", value: "created_at", name: "created_at", filterable: true, sortable: true},
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than 3 characters",
          v => (v && v.length <= 300) || "Must be less than or equal to 300 characters"
        ],
        valueRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than 1 characters",
          v => (v && v.length <= 5) || "Must be less than or equal to 5 characters"
        ],
        selectRule: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select an option';
            else return true;
          }
        ],
      },
      freFilter: [],
      items: [],
      dialog: false,
      editedItem: {
        payment_during_construction: '',
        payment_at_handover: '',
        payment_after_handover: '',
        start_date: '',
        down_payment: '',
      },
      detailDialog: false,
      detailItem: {},
      showUploadedFiles: false
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.datatableScrollToTop('mrc-ptf-datatable')
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_PROJECTPAYMENTPLANS_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.description = '';
        }, 500)
      }
      if (!val) this.editedItem = {
        payment_during_construction: '',
        payment_at_handover: '',
        payment_after_handover: '',
        start_date: '',
        down_payment: '',
      };
      if (!val) bus.$emit("projectPaymentPlanDistributionByModal");
    },
    project() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.getFrequencies()
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 8 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(PROJECTPAYMENTPLANS_LIST, {
          project_id: this.project.id,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(type, item) {
      this.$store.dispatch(GET_PROJECTPAYMENTPLANS_DETAILS, item.id).then((data) => {
        if (type === 'edit') {
          this.editedItem = data.records || {};
          this.dialog = !this.dialog;
          this.showUploadedFiles = true;
        } else {
          this.detailItem = data.records || {};
          this.detailDialog = !this.dialog;
        }
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_PROJECTPAYMENTPLANS;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "project_id", this.project.id);
        this.$set(item, "project_payment_plan_distribution_pp", this.$store.state.projectpaymentplans.projectPaymentPlanDistribution);
        this.$set(item, "project_payment_plan_distribution_phpp", this.$store.state.projectpaymentplans.projectPaymentPlanDistributionPP);
        if (id) {
          method = UPDATE_PROJECTPAYMENTPLANS;
        }
        this.$store.dispatch(CLEAR_PROJECTPAYMENTPLANS_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {
            payment_during_construction: '',
            payment_at_handover: '',
            payment_after_handover: '',
            start_date: '',
            down_payment: '',
          }
          this.dialog = !this.dialog
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete '" + item.title + "'?", clr: 'red', callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_PROJECTPAYMENTPLANS, {
            'id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    pahKeyup(e) {
      if (this.editedItem.payment_at_handover || this.editedItem.down_payment || this.editedItem.payment_after_handover) {
        let val = Number(this.editedItem.payment_at_handover) + Number(this.editedItem.down_payment) + Number(this.editedItem.payment_after_handover);
        this.editedItem.payment_during_construction = String(100 - val)
      } else {
        this.editedItem.payment_during_construction = ''
      }
    },
    itemRowBackground(item) {
      if (item.distributed !== '100') return 'red-row';
    },
    getFrequencies() {
      this.$store.dispatch(FREQUENCIES_PP_SIMPLE_LIST).then((data) => {
        this.freFilter = data.records.frequenciesList;
      });
    },
    updateStatus(status, id, column) {
      this.$store.dispatch(CLEAR_PROJECTPAYMENTPLANS_ERRORS);
      let data = {
        status: status,
        column: column
      };
      this.$store.dispatch(UPDATE_PROJECTPAYMENTPLANS_STATUS, {'slug': id, data: data}).then(() => {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.projectpaymentplans.errors
    })
  }
};
</script>