<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeDetailDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        <span v-html="item.title"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="activeTabDetail" background-color="#002366" left dark>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Basic Details</v-tab>
      <v-tab>Description</v-tab>
      <v-tab>Media</v-tab>
      <v-tab>Social Media Handler</v-tab>
      <v-tab>SEO Handler</v-tab>
      <v-tab>Features</v-tab>

      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <h6>Title:</h6>
              <p v-html="item.title"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>No. Of Bedroom:</h6>
              <p v-html="item.no_of_bedrooms ? item.no_of_bedrooms : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>No. Of Bathroom:</h6>
              <p v-html="item.no_of_bathrooms ? item.no_of_bathrooms : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Elevation:</h6>
              <p v-html="item.elevation ? item.elevation : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Property Type:</h6>
              <p v-html="item.property_type ? item.property_type.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Floor Level:</h6>
              <p v-html="item.floor_level ? item.floor_level : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Minimum Plot Area:</h6>
              <p v-html="item.min_plot_area ? item.min_plot_area + ' (' + item.min_plot_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Maximum Plot Area:</h6>
              <p v-html="item.max_plot_area ? item.max_plot_area + ' (' + item.max_plot_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Minimum Built Up Area:</h6>
              <p v-html="item.min_built_up_area ? item.min_built_up_area + ' (' + item.min_built_up_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Maximum Built Up Area:</h6>
              <p v-html="item.max_built_up_area ? item.max_built_up_area + ' (' + item.max_built_up_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Balcony Area:</h6>
              <p v-html="item.balcony_area ? item.balcony_area + ' (' + item.balcony_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Terrace Area:</h6>
              <p v-html="item.terrace_area ? item.terrace_area + ' (' + item.terrace_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Suite Area:</h6>
              <p v-html="item.suite_area ? item.suite_area + ' (' + item.suite_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Total Area:</h6>
              <p v-html="item.total_area ? item.total_area + ' (' + item.total_area_unit + ')' : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Designed By:</h6>
              <p v-html="item.designed_by_brand_titles ? item.designed_by_brand_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Furnished By:</h6>
              <p v-html="item.furnished_by_brand_titles ? item.furnished_by_brand_titles : '-'"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p v-html="item.description ? item.description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-tabs
            v-model="activeTabDetailInner"
            background-color="primary" left dark>
          <v-tab>Gallery</v-tab>
          <v-tab>Brochure</v-tab>

          <v-tab-item>
            <Gallery v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0" :items="item.gallery_files"/>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('brochure_files') && item.brochure_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.brochure_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="item.hasOwnProperty('youtube_videos_links') && item.youtube_videos_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Videos:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_videos_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('youtube_playlist_links') && item.youtube_playlist_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Playlist:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_playlist_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('virtual_tour_links') && item.virtual_tour_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Virtual Tour:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.virtual_tour_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('website_urls') && item.website_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Website URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.website_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('instagram_urls') && item.instagram_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Instagram URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.instagram_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('facebook_urls') && item.facebook_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Facebook URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.facebook_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('twitter_urls') && item.twitter_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Twitter URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.twitter_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('linkedin_urls') && item.linkedin_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Linkedin URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.linkedin_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h6>Meta Title:</h6>
                <p v-html="item.meta_title ? item.meta_title : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Meta Keywords:</h6>
                <p v-html="item.meta_keywords ? item.meta_keywords : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Focus Keywords:</h6>
                <p v-html="item.focus_keywords ? item.meta_keywords : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Meta Description:</h6>
                <p v-html="item.meta_description ? item.meta_description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat v-if="projectUnitTypeFeaturesOld.length > 0">
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Title
                  </th>
                  <th class="text-left">
                    Floor
                  </th>
                  <th class="text-left">
                    Width
                  </th>
                  <th class="text-left">
                    Length
                  </th>
                  <th class="text-left">
                    Total
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in projectUnitTypeFeaturesOld"
                    :key="item.id"
                >
                  <td>{{ item.title }}</td>
                  <td>{{ item.floor }}</td>
                  <td>{{ item.width ? item.width + ' ' + item.width_unit : '' }}</td>
                  <td>{{ item.length ? item.length + ' ' + item.length_unit : '' }}</td>
                  <td>{{ item.total_area }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ProjectUnitTypeFeatures from "@/view/pages/realstate/projects/inc/ProjectUnitTypeFeatures";
import Gallery from "@/view/pages/realstate/media/Gallery";
export default {
  name: 'project-internal-layout-details',
  components: {Gallery, ProjectUnitTypeFeatures},
  props: ['item', 'projectUnitTypeFeaturesOld'],
  data() {
    return {}
  },
  watch: {
    item() {
      this.activeTabDetail = 0
      this.activeTabDetailInner = 0
    }
  },
  mounted() {},
  methods: {},
  computed: {}
};
</script>