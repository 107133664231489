<template>
  <div>
    <v-card>
      <v-toolbar
          dark
          color="#00004d"
      >
        <v-btn
            icon
            dark
            @click="$emit('closePrDialog')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>Move Project To Development/Project Unit Details</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              :disabled="(selectedProjects.length === 0 || !selectedDevelopment) && (selectedProjects.length === 0 || !selectedProjectTM)"
              dark
              text
              @click="moveProjectsToDD"
          >
            Move
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-dialog persistent v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
          <div class="d-flex">
            <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
              <i style="color: #fff" class="flaticon2-refresh-1"></i>
            </v-btn>
          </div>
        </template>
      </v-dialog>
      <hr style="margin:0"/>
      <div class="col-md-12">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
              <div class="text-center font-weight-bold">
                Search/Filters
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form ref="searchForm" v-on:submit.prevent="hitAdvanceSearch(false)">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-autocomplete
                        v-model="searchFormData.developmentSearch"
                        :items="developmentsFilter"
                        item-text="commercial_title"
                        item-value="id"
                        clearable
                        outlined
                        dense
                        deletable-chips
                        chips
                        :loading="isDevelopmentsLoaded"
                        small-chips
                        cache-items
                        hide-details
                        label="Development (min. 3 characters required)"
                        @keyup="developmentsFilterKeyUp"
                    >
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-html="data.item.country ? data.item.country.title : ''"></span>
                              <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span v-html="data.item.city ? data.item.city.title : ''"></span>
                              <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <button :disabled="loading" type="submit" style="width: 100%"
                            class="btn btn-success theme-btn ml-auto mr-2">
                      Search
                      <v-icon class="ml-2" small>fas fa-search</v-icon>
                    </button>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                            class="btn btn-primary ml-auto">
                      Reset
                      <v-icon class="ml-2" small>fas fa-times</v-icon>
                    </button>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-row>
        <v-col cols="8">
          <v-card-title>
            Projects ({{ addTSBVZ(totalRecords) }})
<!--            <v-spacer></v-spacer>-->
<!--            <v-text-field-->
<!--                @keydown.enter="searchQueryData"-->
<!--                append-icon="search"-->
<!--                label="Search"-->
<!--                single-line-->
<!--                hide-details-->
<!--            ></v-text-field>-->
          </v-card-title>
          <v-data-table
              :headers="headers"
              show-select
              dense
              v-model="selectedProjects"
              :items="items"
              :options.sync="pagination"
              :server-items-length="totalRecords"
              :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
              :loading="loading"
              mobile-breakpoint="100"
              class="elevation-0">
            <template v-slot:item.title="{ item }">
              <div class="text-truncate">
                <span v-html="item.country ? item.country.title : ''"></span>
                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                <span v-html="item.city ? item.city.title : ''"></span>
                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                    dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                    dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                    dark x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.development && item.development.parent_development && item.development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.development && item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
                <v-icon v-if="item.development && item.development.parent_development" x-small
                        class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.development ? item.development.commercial_title : ''"></span>
                <v-icon v-if="item.development" x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.title"></span>
              </div>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card-title>
            <v-autocomplete
                class="mb-5"
                v-model="selectedDevelopment"
                :items="developmentsFilter"
                item-text="commercial_title"
                item-value="id"
                clearable
                outlined
                dense
                deletable-chips
                chips
                :loading="isDevelopmentsLoaded"
                small-chips
                cache-items
                hide-details
                label="Development (min. 3 characters required)"
                @keyup="developmentsFilterKeyUp"
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-html="data.item.country ? data.item.country.title : ''"></span>
                      <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span v-html="data.item.city ? data.item.city.title : ''"></span>
                      <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.parent_development && data.item.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-autocomplete
                v-model="selectedProjectTM"
                :items="projectsFilter"
                item-text="title"
                item-value="id"
                clearable
                outlined
                dense
                deletable-chips
                chips
                :loading="isProjectsLoaded"
                small-chips
                cache-items
                hide-details
                label="Project (min. 3 characters required)"
                @keyup="projectsFilterKeyUp"
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span v-html="data.item.country ? data.item.country.title : ''"></span>
                      <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span v-html="data.item.city ? data.item.city.title : ''"></span>
                      <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                      <v-icon
                          v-if="data.item.development && data.item.development.parent_development"
                          x-small class="ml-1 mr-1">fas fa-angle-double-right
                      </v-icon>

                      <span
                          v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                      <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                        fa-angle-double-right
                      </v-icon>

                      <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-card-title>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  PROJECTS_DATA_LIST, MOVE_PROJECTS_TO_DD
} from "@/core/services/store/projects.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {DEVELOPMENTS_SIMPLE_LIST} from "@/core/services/store/developments.module";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";

export default {
  name: 'project-simple-data',
  data() {
    return {
      headers: [
        {text: 'Title', value: 'title', name: 'title', filterable: true, sortable: false},
        {text: 'Created', align: ' d-none', value: 'created_at', name: 'created_at', filterable: false, sortable: true}
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 200,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [200, 500],
      headerindex: "",
      columns: [],
      items: [],
      dialog: false,
      developmentsFilter: [],
      isDevelopmentsLoaded: false,
      projectsFilter: [],
      isProjectsLoaded: false,
      selectedProjects: [],
      selectedDevelopment: '',
      selectedProjectTM: '',
      searchFormData:{}
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
  },
  mounted() {
    for (let i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(PROJECTS_DATA_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = false;
          resolve({
            items,
            total
          });
        });
      });
    },
    developmentsFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isDevelopmentsLoaded = true;
        this.$store
            .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
              q: e.target.value
            })
            .then(data => {
              this.developmentsFilter = data.records.developmentsList;
              this.isDevelopmentsLoaded = false;
            });
      }
    },
    projectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isProjectsLoaded = true;
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: 'none'
              })
              .then(data => {
                that.projectsFilter = data.records.projectsList;
                that.isProjectsLoaded = false;
              });
        }
      }, 900);
    },
    hitAdvanceSearch(reset = false) {
      if (reset) {
        this.searchFormData = {};
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    moveProjectsToDD() {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to move?", clr: 'red', callback: function () {
          let data = {
            project_ids: that.selectedProjects,
            project_id: that.selectedProjectTM,
            development_id: that.selectedDevelopment
          };
          that.$store.dispatch(MOVE_PROJECTS_TO_DD, {data: data})
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                  that.selectedDevelopment = '';
                  that.selectedProjectTM = '';
                  that.selectedProjects = [];
                  that.$emit('refreshMainTable')
                });
              });
        }
      });
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.projects.errors
    })
  }
};
</script>